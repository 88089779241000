<template>
  <div>
    <div v-if="lists.length > 0">
      <carousel
        :autoplay="true"
        :nav="false"
        :loop="true"
        :dots="false"
        :items="slidesno"
         
      >
        <div v-for="(list, index) in lists" :key="index">
        <router-link :to="list.Link">
            <img :src="path + list.imageUrl" />
        </router-link>
             <!-- <img :src="path + list.imageUrl" /> -->
      
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import Config from "../config";
export default {
  components: {
    carousel,
  },
  props: {
    lists: [],
    slidesno: Number,
  },
  data: function () {
    return {
      path: Config.BASE_URL_IMG,
    };
  },
  // created(){
  //   alert("hi")
  //   if(this.lists[0].noofslides > 1){
  //      console.log(this.lists[0].noofslides);
  //   let noslide = this.lists[0].noofslides;
  //    this.slides=noslide;

  //   } else {
  //      this.slides= 1;

  //   }

  // }
};
</script>
<style scoped>
.container {
  font-family: arial;
  font-size: 24px;
  margin: 50px;
  width: 350px;
  height: 200px;
  /* Setup */
  position: relative;
}

.child {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}
</style>
