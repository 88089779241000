var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.lists.length > 0)?_c('div',[_c('carousel',{attrs:{"responsive":{
        0: {
          items: 2,
          nav: false,
          dots: false,
          loop: true,
          autoplay: false,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          margin: 20,
        },
        600: {
          items: 5,
          nav: false,
          dots: false,
          loop: true,
          autoplay: true,
          autoplayTimeout: 4000,
          autoplayHoverPause: true,
          margin: 80,
        },
      }}},_vm._l((_vm.lists),function(list,index){return _c('div',{key:index},[_c('router-link',{attrs:{"to":{
            name: 'AuctionList',
            params: { vehTypeName: list.vehTypeName },
          }}},[_c('img',{attrs:{"src":_vm.path + list.imageUrl}})])],1)}),0)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }