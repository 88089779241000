<template>
  <div class="uren-banner_area uren-banner_area-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="banner-item img-hover_effect">
              <h1>{{ bannerlist }}</h1>
            <a href="">
              <img
                class="img-full"
                src="/assets/images/banner/1.jpg"
                alt="Uren's Banner"
              />
            </a>
          </div> 
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  data(){
    return{
        bannerlist:''
    }
    

  },
   created() {
    this.Banner();
  },
    methods: {
    Banner() {
      
      userService
        .Banner()
        .then((response) => {
          this.bannerlist = response.data.Data;
          //  console.log(this.bannerlist);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
