<template>
  <div class="Ntcontainer">
    <div>
      <section class="section">
        <div class="card" v-if="checkdata == false" style="position:relative;">
          <div class="card-content">
            <div class="text-center">
              <img src="/assets/images/no_result_found.gif" />
             
            </div>
          </div>
        </div>

        <div v-if="checkdata == true" class="loader">
          <!-- <ul class="loading-animation" hidden>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul> -->
          
          <img src="/assets/images/menu/logo/2.png" class="loader-logo"/>

          
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Config from "../config";
export default {
  data() {
    return {
      message: "Loading Indicators",
      isLoading: false,
      delayLoading: Config.pagelaoddelay,
    };
  },
  props: {
    checkdata: Boolean,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.Ntcontainer {
  min-height: 130px;
  margin-top: 20px;
}
/* .loading-overlay {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(100, 100, 100, 0.5);
}

i.fa.fa-cog {
  font-size: 3em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
} */
.lpoader {
  margin-top: 140px;
}
.loading-animation {
  list-style: none;
  position: relative;
  height: 1px;
  width: 200px;
  border-bottom: 1px dashed #aaa;
  position: relative;
}
.loading-animation li {
  margin-top: -3px;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: blue;
  opacity: 0;
  box-shadow: 0px 0px 4px blue, 0px 0px 8px blue, 0px 0px 12px blue,
    0px 0px 18px blue;
  position: absolute;
  left: -10%;
  animation-name: loading;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: normal;
}
/* alternate animation */
.loading-animation.alternate li {
  animation-name: loading2;
}

.loading-animation li:nth-child(1) {
  animation-delay: 250ms;
}
.loading-animation li:nth-child(2) {
  animation-delay: 500ms;
}
.loading-animation li:nth-child(3) {
  animation-delay: 750ms;
}
.loading-animation li:nth-child(4) {
  animation-delay: 1s;
}
.loading-animation li:nth-child(5) {
  animation-delay: 1.25s;
}

/* fading only */
@keyframes loading {
  0% {
    left: 0%;
    opacity: 0;
  }
  20%,
  60% {
    left: 50%;
    opacity: 1;
  }
  90% {
    left: 100%;
    opacity: 0;
  }
  100% {
    left: 100%;
  }
}

/* shrinking and fading */
@keyframes loading2 {
  0% {
    left: 0%;
    opacity: 0;
    height: 1px;
    width: 1px;
    margin-top: -2px;
  }
  20%,
  60% {
    left: 50%;
    opacity: 1;
    height: 10px;
    width: 10px;
    margin-top: -3px;
  }
  90% {
    left: 100%;
    opacity: 0;
    height: 1px;
    width: 1px;
    margin-top: 0px;
  }
  100% {
    left: 100%;
  }
}

/* all of this is extra for the demo */
.loading-animation {
  margin: 0 auto;
  margin-top: 100px;
}
.loading-animation:before,
.loading-animation:after {
  color: grey;
  font-family: "Merienda One";
}
.loading-animation:before {
  content: "Loading";
  position: absolute;
  top: -32px;
  left: 39%;
}
.loading-animation:after {
  content: "Please wait";
  position: absolute;
  top: 12px;
  left: 36%;
}
</style>
