<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>
              <div class="col-lg-9">
                <div class="">
                  <h3>Change Password</h3>
                </div>

                <div>
                  <section class="loginformSection">
                    <div>
                      <div class="card card-container">
                        <form name="form" @submit.prevent="handlepasswordreset">
                          <div v-if="!successful">
                            <div class="form-group">
                              <label for="password">Current Password</label>
                              <input
                                v-model="user.Currentpassword"
                                v-validate="'required|min:6|max:40'"
                                :type="passwordTypeCurrent"
                                class="form-control"
                                name="current password"
                                placeholder="Current Password"
                              />
                              <div class="control passwordTypeCls">
                                <div class="button" @click="toggleShowCurrent">
                                  <span class="icon is-small is-right">
                                    <i
                                      class="fas"
                                      :class="{
                                        'fa-eye-slash': showPasswordCurrent,
                                        'fa-eye': !showPasswordCurrent,
                                      }"
                                    ></i>
                                  </span>
                                </div>
                              </div>

                              <div
                                v-if="
                                  submitted && errors.has('current password')
                                "
                                class="alert-danger"
                              >
                                {{ errors.first("Current password") }}
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="password"> New Password</label>
                              <input
                                v-model="user.Newpassword"
                                v-validate="'required|min:6|max:40'"
                                :type="passwordType"
                                class="form-control"
                                name="new password"
                                ref="password"
                                placeholder="New Password"
                              />
                              <div class="control passwordTypeCls">
                                <div class="button" @click="toggleShow">
                                  <span class="icon is-small is-right">
                                    <i
                                      class="fas"
                                      :class="{
                                        'fa-eye-slash': showPassword,
                                        'fa-eye': !showPassword,
                                      }"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                              <div
                                v-if="submitted && errors.has('new password')"
                                class="alert-danger"
                              >
                                {{ errors.first("new password") }}
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="password">Re-enter Password</label>
                              <input
                                v-model="user.reenterpassword"
                                v-validate="
                                  'required|min:6|max:40|confirmed:password'
                                "
                                :type="passwordTypeNew"
                                class="form-control"
                                name="re-enter Password"
                                data-vv-as="password"
                                placeholder="Re-enter Password"
                              />
                              <div class="control passwordTypeCls">
                                <div class="button" @click="toggleShowNew">
                                  <span class="icon is-small is-right">
                                    <i
                                      class="fas"
                                      :class="{
                                        'fa-eye-slash': showPasswordNew,
                                        'fa-eye': !showPasswordNew,
                                      }"
                                    ></i>
                                  </span>
                                </div>
                              </div>
                              <div
                                v-if="
                                  submitted && errors.has('re-enter password')
                                "
                                class="alert-danger"
                              >
                                {{ errors.first("re-enter password") }}
                              </div>
                            </div>
                            <div class="form-group">
                              <button
                                style="font-size: 16px; font-weight: 600"
                                class="btn btn-primary btn-block"
                              >
                                UPDATE
                              </button>
                            </div>
                          </div>
                          <!-- <div class="form-group" style="margin: 0px">
                            <div
                              v-if="message"
                              class="alert alert-warning"
                              role="alert"
                              style="margin: 0px"
                            >
                              {{ message }}
                            </div>
                          </div> -->
                        </form>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
import userService from "../services/user.service";
import userinformation from "../models/localstorage";
// import config from "../config";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      message: "",
      passwordType: "password",
      passwordTypeNew: "password",
      passwordTypeCurrent: "password",
      showPassword: "",
      showPasswordNew: "",
      showPasswordCurrent: "",
    };
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/Changepassword").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  mounted() {
    if (!this.$store.state.auth.user) {
      this.$router.push("/").catch(() => {
        "changepassword";
      });
    } else {
      this.st = userinformation;
    }
  },
  methods: {
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }  if (variant == "success") {
        tittleMessage = "Success";
      } if (variant == "warning") {
        tittleMessage = "Warning";
      }

      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
    toggleShowCurrent() {
      this.showPasswordCurrent = !this.showPasswordCurrent;
      if (this.showPasswordCurrent) {
        this.passwordTypeCurrent = "text";
      } else {
        this.passwordTypeCurrent = "password";
      }
    },
    toggleShowNew() {
      this.showPasswordNew = !this.showPasswordNew;
      if (this.showPasswordNew) {
        this.passwordTypeNew = "text";
      } else {
        this.passwordTypeNew = "password";
      }
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handlepasswordreset() {
      this.message = "";
      this.submitted = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          let cusid = this.st[0].customerId;
          userService.changepassword(cusid, this.user).then(
            (data) => {
              if (data.data.Message == "Success") {
                this.makeToast("success", "Password changed successfully");
                //this.message = "Password changed successfully";
                this.successful = true;
              } else {
                this.makeToast("warning", data.data.Message);
                this.successful = false;
              }
            },
            (error) => {
              let resMessage =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();

              this.successful = false;
              this.makeToast("danger", resMessage);
            }
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.alert-warning {
  color: #ffffff;
  background-color: #40c40999;
  border-color: #40c40999;
  margin-bottom: 0px;
}
label {
    display: block;
    margin-bottom: 2px;
}

.card-container.card {
  max-width: 380px !important;
}
.form-group {
    margin-bottom: 25px;
}
.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  /* margin: 0 auto 25px; */

  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
  border: none;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.alert-danger {
  color: #cb0013;
    background-color: #ffffff00;
    border-color: #f5c6cb00;
    padding: 8px 0px 0px 0px;
}
</style>
