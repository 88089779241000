<template>
  <div>
    <div class="col-lg-9">
      <br />
      <h3>Recent Listing</h3>
    </div>
    <div v-if="!items">
      <notfound :checkdata="initialLoading"></notfound>
    </div>
    <div class="row" v-else>
      <div class="col-md-4" v-for="vechlist in items" :key="vechlist.vehId">
        <div class="">
          <article class="pdtSliderhome">
            <div>
              <div>
                <img
                  :src="path + '/' + vechlist.vehImage1"
                  class="img-fluid"
                  style="object-fit: cover; width: 100%; height: 160px"
                />
              </div>
              <div class="leftright10pad">
                <h6>
                  {{ vechlist.brandName }} {{ vechlist.modelName }}
                  {{ vechlist.manYear }}
                </h6>
              </div>
              <!-- <div class="leftright10pad text-center pdtTileTimer">
                        <span class="">Registration :  {{ vechlist.vehRegNo  }}
                        </span>
                      </div> -->
              <div class="leftright10pad">
                <div class="pdtHomelistrow">
                  <div class="pdtHomelistcolleft">Registration</div>
                  <div class="pdtHomelistcolright">{{ vechlist.vehRegNo }}</div>
                </div>
                <div class="pdtHomelistrow">
                  <div class="pdtHomelistcolleft">Brand Name</div>
                  <div class="pdtHomelistcolright">
                    {{ vechlist.brandName }}
                  </div>
                </div>
                <div class="pdtHomelistrow">
                  <div class="pdtHomelistcolleft">Year Of Make</div>
                  <div class="pdtHomelistcolright">{{ vechlist.manYear }}</div>
                </div>
                <div class="pdtHomelistrow">
                  <div class="pdtHomelistcolleft">Price</div>
                  <div
                    class="pdtHomelistcolright"
                    v-if="vechlist.vehPrice == null"
                  >
                    ₹ 0
                  </div>
                  <div class="pdtHomelistcolright" v-else>
                    {{ vechlist.vehPrice | currency }}
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'UpdateVechDetails',
                    params: { url_key: vechlist.vehId },
                  }"
                  class="rlistviewD"
                >
                  View Details</router-link
                >
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <div class="overflow-auto">
      <div class="mt-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="6"
          disabled
          hidden
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import Config from "@/config";
export default {
  data() {
    return {
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      perPage: 10,
      currentPage: 1,
      initialLoading: true,
      //  :src="imags + sellervechlists.vehImage1"
      // getVehiclesListBySellerId
    };
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getVehiclesListBySellerId();
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    getVehiclesListBySellerId() {
      let id = this.st.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getVehiclesListBySellerId(id)
          .then((response) => {
            this.sellervechlists = response.data.Data;
            //console.log(response.data.Data);
            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/vendor").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    items() {
      return this.sellervechlists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.sellervechlists.length;
    },
  },
};
</script>
<style>
.rlistviewD {
  background-color: #0094da;
  color: white;
  padding: 5px;
  width: 100%;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin-top: 5px;
  font-weight: 500;
}
.pdtHomelistrow {
  font-weight: 500;
}

.pdtSliderhome {
  box-shadow: 0px 0px 5px #b3b3b3;
}
</style>
