<template>
  <!-- Begin Footer Area -->
  <div class="uren-footer_area noPrint">
    <div class="footer-top_area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12" v-if="isHomePage">
            <div class="newsletter-area">
              <div class="newsletter-form_wrap">
                <!-- its work redirect url issue -->
                <vue-mailchimp-email-signup-form
                  :element-id="'first-email-signup-form'"
                  :url="'https://gmail.us18.list-manage.com/subscribe/post?u=4a1e769315c037adf3ed168ba&amp;id=19c83d09ba&amp;f_id=007922e7f0'"
                  :title="'Join Our Newsletter Now'"
                  :subtitle="'Get E-mail updates about our latest auction and special offers.'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="downloadApp">
      <div class="container">
        <div class="row" style="align-items: center">
          <div class="col-md-6">
            <div class="appdown-img">
              <img src="/assets/images/appdownleft.png" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="appContentsec">
              <h4>Download Our New App</h4>
              <p>
                Are you ready to join the exciting world of car auctions and make your bids on your dream vehicles? Download our app today and start bidding with ease.
              </p>
              <div class="qrBtns">
                
                <div class="pastorebtns">
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=in.ibidz.customer" target="blank"><img src="/assets/images/playstore.png" /></a>
                    <img src="/assets/images/qrplaystore.png" class="qrAppD">
                  </div>
                  <div>
                    <a href="https://apps.apple.com/in/app/ibidz/id6470708315" target="blank"><img src="/assets/images/appstore.png" /></a>
                    <img src="/assets/images/qrappstore.png" class="qrAppD">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-middle_area">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="footer-widgets_info">
              <div class="footer-widgets_logo">
                <a href="/">
                  <img
                    src="/assets/images/menu/logo/2.png"
                    alt=" Footer Logo"
                    style="width: 220px"
                  />
                </a>
              </div>

              <div class="uren-social_link">
                <ul style="padding-left: 0px">
                  <li class="facebook">
                    <a
                      href="https://www.facebook.com/profile.php?id=100094279611184&mibextid=LQQJ4d"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Facebook"
                    >
                      <i class="fab fa-facebook"></i>
                    </a>
                  </li>
                  <li class="twitter">
                    <a
                      href="https://twitter.com/ibidzofficial?s=11"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Twitter"
                    >
                      <i class="fab fa-twitter-square"></i>
                    </a>
                  </li>
                  <li class="google-plus">
                    <a
                      href="https://www.youtube.com/@iBidzCars"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Google Plus"
                    >
                      <i class="fab fa-youtube"></i>
                    </a>
                  </li>
                  <li class="instagram">
                    <a
                      href="https://www.instagram.com/ibidz_official?igshid=MzRlODBiNWFlZA%3D%3D"
                      data-toggle="tooltip"
                      target="_blank"
                      title="Instagram"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="footer-widgets_area">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Information</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li>
                        <a href="/FeedBackForm">Share Feedback</a>
                      </li>
                      <li>
                        <a href="/SellersTerms">Sellers Terms</a>
                      </li>
                      <!-- <li>
                        <a href="/ComingSoon">Privacy Policy</a>
                      </li> -->
                      <li>
                        <a href="/CustomerTerms">Customer Terms</a>
                      </li>
                      <li>
                        <a href="/RefundPolicy">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Quick Links</h3>
                  </div>
                  <div class="footer-widgets">
                    <ul>
                      <li><a href="/About">About Us</a></li>
                      <li>
                        <a href="/Auction">Auctions</a>
                      </li>
                      <li>
                        <a href="/PrivacyPolicy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/vendorRegister">Register as Vendor</a>
                      </li>
                      <li>
                        <a href="/vendorLogin">Login as Vendor</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="footer-widgets_title">
                    <h3>Contact Us</h3>
                  </div>

                  <div class="widgets-essential_stuff">
                    <ul>
                      <li class="uren-address">
                        <span>Address:</span> FOURO TECH Pvt. Ltd.
2nd Floor, 2F, Vattoly Annexe, SRM Road, North, Ernakulam
GSTIN:32AAECF7684E1ZM
                      </li>
                      <li class="uren-phone">
                        <span>Call Us: </span>
                        <a href="tel://+919544509000"> +91 9544 509 000</a>
                      </li>
                      <li class="uren-email">
                        <span>Email: </span>
                        <a href="mailto://mail@fourotech.com">
                          mail@fourotech.com</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="whatsappFloat">
        <a href="https://wa.me/+919544509000?text=Hello%20there!" class="whatLink" target="blank">
          <img src="https://cdn-icons-png.flaticon.com/512/1384/1384055.png" class="img-fluid" />
        </a>
      </div>

    <div class="footer-bottom_area">
      <div class="container-fluid">
        <div class="footer-bottom_nav">
          <div class="">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="copyright">
                  <span
                    ><a style="color: white" :href="copyrighturl">
                      {{ copyright }}</a
                    ></span
                  >
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                  <div class="text-center">
                    <span><a style="color:white;">© {{new Date().getFullYear()}} FOURO TECH Pvt. Ltd. | All rights reserved.</a></span>
                  </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="payment">
                  <a href="#">
                    <img
                      src="/assets/images/footer/payment/1.png"
                      alt="Payment Method"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer Area End Here -->
</template>
<!-- <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css"></link> -->
<script>
import Config from "../config";
// import { VueMailchimpEmailSignupForm } from "vue-mailchimp-email-signup-form";
export default {
  data() {
    return {
      copyright: Config.copyright,
      copyrighturl: Config.copyrighturl,
      onError: "",
      onSuccess: "",
      isHomePage: false,
    };
  },

  created() {
    var currentURL = window.location.href;

    // Extract the part of the URL after the last slash
    var lastPartOfURL = currentURL.substr(currentURL.lastIndexOf("/") + 1);

    // Check if there is any value after the last slash
    if (lastPartOfURL.length > 0) {
      this.isHomePage = false;
    } else {
      this.isHomePage = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@media only screen and (max-width: 600px) {
  .footer-middle_area {
    text-align: center;
  }
}
</style>
