<template>
  <div>
    <a class="toggle-left-menu" @click="toggleList()"><i class="fas fa-bars"></i> Menu</a>
    <div>
      <ul
       v-if="showList"
        class="nav myaccount-tab-trigger"
        id="account-page-tab"
        role="tablist"
      >
       
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/MyAccount"
            ><i class="fas fa-user-alt"> </i> Account</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/MyBidz"
            ><i class="fas fa-gavel"> </i> Bids</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/mywishlist"
            ><i class="fas fa-heart"> </i> Wishlist</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-packages-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-packages"
            aria-selected="true"
            to="/package"
            ><i class="fas fa-cubes"> </i> Packages
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/MyWinnings"
            ><i class="fas fa-trophy"> </i> Winnings</router-link
          >
        </li>


        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/PaymentDues"
            ><i class="fas fa-credit-card" aria-hidden="true"></i> Payment
            Dues</router-link
          >
        </li>

        
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/ClaimHistory"
            ><i class="fas fa-history"> </i> Package Redeem History</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/RefundHistory"
            ><i class="fas fa-microchip"> </i> Refund History</router-link
          >
        </li>
       
  
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-address-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-address"
            aria-selected="false"
            to="/Changepassword"
            ><i class="fas fa-key"> </i> Change Password</router-link
          >
        </li>


        <li class="nav-item" v-if="currentUser">
          <a
            class="nav-link"
            id="account-details-tab"
            href
            @click.prevent="logOut"
            role="tab"
            ><i class="fas fa-lock"></i> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showList: true,
      currentuserinfo: "",
    };
  },
  mounted() {
    var className = document.getElementsByClassName("nav-link");    
    for (var i = 0; i < className.length; i++) {
      if (className[i].className.includes('router-link-active')) {
       className[i].classList.add('active');
      }
    }    
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
      if(this.isMobileScreen){
        this.showList = false;
      }



    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.currentuserinfo = "";
      location.reload();
        this.$router.push("/").catch(() => {
          "navbar";
        });
      
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isMobileScreen: function () {
      return window.innerWidth <= 768; // You can adjust the width threshold as needed
    },
  },
};
</script>
<style scoped>
.pdtSliderhome {
  background-color: white;
  border-radius: 5px;
  margin: 10px 8px;
  padding: 5px;
}

.btn-upload {
  padding: 10px 20px;
  margin-left: 10px;
}
.upload-input-group {
  margin-bottom: 10px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  height: 45px;
}
.imgUploadVehicle {
  margin-top: 20px;
  width: 100%;
}
.nav-item a:hover {
  background-color: #0094d9 !important;
  color: white !important;
}
.router-link-exact-active {
  border-bottom: 1px solid #123456;
}
</style>
