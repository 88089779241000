<template>
  <div>
    <div class="card card-container">
      <!-- <img
        id="profile-img"
        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
        class="profile-img-card"
      /> -->
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Email/Phone</label>
          <input
            v-model="user.username"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="username"
            placeholder="Username"
          />
          <div
            v-if="errors.has('username')"
            class="alert alert-danger"
            role="alert"
          >
            Username is required!
          </div>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            v-model="user.password"
            v-validate="'required'"
            :type="passwordType"
            class="form-control password-cust"
            name="password"
            id="password-cust"
            placeholder="Password"
          />
          <div class="control passwordTypeCls">
            <div class="button" @click="toggleShow">
              <span class="icon is-small is-right">
                <i
                  class="fas"
                  :class="{
                    'fa-eye-slash': showPassword,
                    'fa-eye': !showPassword,
                  }"
                ></i>
              </span>
            </div>
          </div>
          <div
            v-if="errors.has('password')"
            class="alert alert-danger"
            role="alert"
          >
            Password is required!
          </div>
        </div>
        <!-- <div class="form-group">
          <button class="btn btn-primary btn-block loginBtn" :disabled="loading">
            <span v-show="loading" class="spinner-border spinner-border-sm"></span>
            <span>LOGIN</span>
          </button>
        </div> -->
        <div class="row" style="margin-top: 25px">
          <div class="col-6">
            <button
              class="btn btn-primary loginBtnText"
              style="width: 100%"
              :disabled="loading"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span class="">LOGIN</span>
            </button>
          </div>
          <div class="col-6">
            <router-link
              class="btn btn-danger loginBtnText"
              style="width: 100%; color: white !important"
              to="Register"
              >REGISTER</router-link
            >
            <!-- <button class="btn btn-danger"  to="Register" style="width:100%;">REGISTER</button> -->
          </div>
        </div>
        <div class="form-group">
          <label for="password"
            ><a href="/forgotpassword" rel="noopener noreferrer"
              >Forgot Password ?</a
            >
          </label>
        </div>
        <!-- <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div> -->
        <!-- <div>
          <router-link class="loginBtnz" :to="{ name: 'vendorLogin', }">Vendor Login</router-link> 
        </div> -->
      </form>
    </div>
  </div>
</template>
<script>
import User from "../models/user";
// import config from "../config";

export default {
  name: "Login",
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      passwordType: "password",
      showPassword: "",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/MyAccount").catch(() => {
        "login";
      });
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              this.$router.push("/Auction").catch(() => {
                "handlelogin";
              });
            },
            (error) => {
              this.loading = false;
              let resMessage =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();

                this.makeToast('danger',resMessage);
            }
          );
        }
      });
    },
    makeToast(variant, body_content) {
      let tittleMessage =variant;
      if(variant == 'danger'){
        tittleMessage ='Error'
      }
      if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>
<style scoped>
.loginBtnz {
  color: white;
  background-color: #0093d9;
  padding: 4px;
  border-radius: 3px;
  margin: 0px auto;
  display: block;
  text-align: center;
}

.loginBtn {
  background-image: linear-gradient(to right, #000e88, #9000a7);
  border: none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  /* max-width: 380px !important; */
  /* padding: 40px 40px; */
  margin-bottom: 0px;
}

.card {
  /* background-color: #dedede !important; */
  padding: 10px 0px 0px;
  border-radius: 2px;
  border: none;
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
