<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard></sidebar-dashboard>
              </div>
              <!-- ///// -->
              <div class="col-lg-9">
                <div v-if="!items.length">
                  <h3>Auction History</h3>
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <div v-else>
                  <div style="display: flex; justify-content: space-between">
                    <h3>Auction History</h3>
                    <div
                      style="
                        float: right;
                        margin-right: 10px;
                        margin-bottom: 10px;
                      "
                    >
                      <button
                        class="btn btn-primary noPrint"
                        :style="printBtn"
                        @click="printCom"
                      >
                        Print
                      </button>
                    </div>
                  </div>
                  <section>
                    <div>
                      <div
                        class="pdtListingWrap"
                        v-for="vechlist in items"
                        :key="vechlist.vehId"
                      >
                        <div class="row">
                        
                          <div class="col-md-9 bidborderright">
                            <div class="">
                              <div class="bidlistDetailswrapwidth85">
                                <div class="bidlistDetailswrap">
                                  <a href="#"
                                    ><h4>
                                      {{ vechlist.brandName }}
                                      <span class="cModalname">{{
                                        vechlist.modelName
                                      }}</span>
                                    </h4></a
                                  >
                                </div>
                                <div class="auction-customer-name">
                                 <img src="https://static.thenounproject.com/png/4680233-200.png"> Top Bid : 
                                  <i class="far fa-user"></i
                                  ><span v-if="vechlist.custName">{{
                                    vechlist.custName
                                  }}</span>
                                </div>
                                <div
                                  v-if="vechlist.actualMaturityDate < datenow"
                                  class="AuctionStatus"
                                >
                                  <p>{{ vechlist.auctionStatus }}</p>
                                </div>
                              </div>
                              <div class="bidlistDetailswrapwidth15">
                               
                              </div>
                            </div>
                            <div
                              class="row hidinglast2on768-991"
                              style="row-gap: 10px"
                            >
                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-tachometer-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                     
                                      <p class="fontWeightbidlist">
                                        {{ vechlist.kmClocked }} KM
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="far fa-calendar-alt pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                    
                                      <p class="fontWeightbidlist greenIcon">
                                        {{ vechlist.actualMaturityDate | formatDate }}
                                        IST
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-sm-4 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="far fa-clock pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                   
                                      <p v-if="vechlist.actualMaturityDate < datenow">
                                        Auction Expired
                                      </p>
                                      <p
                                        v-else
                                        class="fontWeightbidlist greenIcon"
                                      >
                                        <Countdown
                                          :deadline="vechlist.actualMaturityDate"
                                        ></Countdown>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-map-marker-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                 
                                      <p
                                        class="fontWeightbidlist main-tile-location"
                                      >
                                        {{ vechlist.locName }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                    
                                      <p class="fontWeightbidlist">
                                        Inc. {{ vechlist.aucMinBid | currency }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <a href="" @click.prevent="openThread"
                                        ><p class="bidzcarnum">
                                          {{ vechlist.vehRegNo }}
                                        </p></a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <a href="" @click.prevent="openThread"
                                        ><p class="numbidz">
                                          {{ vechlist.bidzCount }}BIDS
                                        </p></a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="text-center">
                              <div
                                class="marginbidtop"
                                style="margin-top: 25px"
                              >
                                <span>CURRENT BID AMOUNT</span>
                                <h4 v-if="vechlist.latestBidAmount == null">
                                  ₹ 0
                                </h4>
                                <h4 v-else>
                                  {{ vechlist.latestBidAmount | currency }}
                                </h4>
                             
                              </div>
                              <div>
                                <div class="row">
                                  <div class="col">
                                    <div>
                                      <a href=""
                                        ><div class="bidzNumberz">
                                          {{ vechlist.bidzCount }} BIDS
                                        </div></a
                                      >
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div>
                                      <router-link
                                        :to="{
                                          name: 'VechDetails',
                                          params: {
                                            url_key: vechlist.vehId,
                                            aucType: 'auctionHistory',
                                          },
                                        }"
                                        ><div class="detailsButtonz">
                                          Details
                                        </div></router-link
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div class="overflow-auto noPrint">
                    <div class="mt-3">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        pills
                        first-number
                        last-number
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>

              <!-- ///// -->
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import Config from "@/config";
import moment from "moment";
export default {
  data() {
    return {
      datenow: null,
      perPage: 10,
      currentPage: 1,
      sellervechlists: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
      //  :src="imags + sellervechlists.vehImage1"
      // getVehiclesListBySellerId
    };
  },
  props: ["changed_url"],
  filters: {
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        //  d = d.toLocaleString("en-US", { timeZone:  Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a");
      }
    },
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },

  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getAuctionListBySellerId();
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
    window.scrollTo(0, 0);
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].sellerId == "" ||
        !this.$store.state.auth.user[0].sellerId == " "
      ) {
        this.$router.push("/AuctionHistory").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
    this.datenow = new Date().toLocaleString("en-US", {
      timeZone: Config.CurrNttimezone.toString(),
    });
  },
  methods: {
    printCom() {
      //  this.printBtn = 'display:none';
      window.print();
    },
    getAuctionListBySellerId() {
      let id = this.st.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getAuctionListBySellerId(id)
          .then((response) => {
            this.sellervechlists = response.data.Data;
            console.log(this.sellervechlists);
            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    items() {
      return this.sellervechlists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.sellervechlists.length;
    },
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .marginbidtop {
    margin-top: 15px !important;
  }
}
@media print {
  #navbarSupportedContent {
    display: none !important;
  }
  .account_border-right {
    display: none !important;
  }
  .noPrint {
    display: none !important;
    visibility: hidden;
  }
}
</style>
