<template>
  <div>
    <!-- Your website content here -->
    
    <!-- Mobile Popup -->
    <div class="mobile-popup" v-if="showPopup">
      <p>Would you like to download our app or continue browsing?</p>
      <!-- <button @click="downloadApp" class="downloadAppBtn btn btn-primary">Download App</button> -->
      <div class="mobilePopAppDownload">
        <a href="https://play.google.com/store/apps/details?id=in.ibidz.customer" target="blank"><img src="../../public/assets/images/playstore.png"/></a>
        <a href="https://apps.apple.com/in/app/ibidz/id6470708315" target="blank"><img src="../../public/assets/images/appstore.png"/></a>
        
      </div>
      <button @click="closePopup" class="continueBrowsingBtn btn btn-info">Continue in browser <i class="fas fa-angle-right"></i></button>
      
    </div>


    <div class="notification-top-bar">
  <p>Oracular IS Completes Acquisition of Logixify Inc. See the <small><a href="https://www.oracular.com/index.php?option=com_content&view=article&id=79">Press Release</a></small></p>
</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false, // Control the visibility of the popup
    };
  },
  created() {
    // Check if the screen width is less than a certain value (e.g., 768 pixels for mobile)
    if (window.innerWidth < 768) {
      // Check if the popup should be displayed (only once per session)
      const hasDisplayedPopup = sessionStorage.getItem("hasDisplayedPopup");
      if (!hasDisplayedPopup) {
        this.showPopup = true;
      }
    }
  },
  methods: {
    downloadApp() {
      // Handle the download app action here (e.g., redirect to app download page)
      window.location.href = "/auction";

      // Set a flag in sessionStorage to indicate that the popup has been displayed
      sessionStorage.setItem("hasDisplayedPopup", "true");

      // Close the popup
      this.showPopup = false;
    },
    closePopup() {
      // Close the popup without setting the flag
      this.showPopup = false;

      // Set a flag in sessionStorage to indicate that the popup has been displayed
      sessionStorage.setItem("hasDisplayedPopup", "true");
    },
  },
};
</script>

<style scoped>
.mobile-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999; /* Ensure the popup is on top of other content */
}
.downloadAppBtn {
  margin-right: 10px;
 
}



</style>
