<template>
  <ul class="vuejs-countdown">
    <div
      v-if="days <= 0 && hours <= 0 && minutes < 10 && seconds > 0"
      class="heart"
    >
      <div>
        <p class="digit counterWarning" style="">
          {{ days | twoDigits }} <span>{{ days > 1 ? " Days" : " Day" }}</span>
        </p>
        <p class="digit counterWarning">
          {{ hours | twoDigits }} :{{ minutes | twoDigits }} :{{
            seconds | twoDigits
          }}
          s
        </p>
      </div>
      <!-- <li>
            <p class="digit  counterWarning">{{ days | twoDigits }} <span >{{ days > 1 ? 'D' : 'D' }}</span></p>
        </li>
        <li >
            <p class="digit  counterWarning">: {{ hours | twoDigits }} <span>{{ hours > 1 ? 'H' : 'H' }}</span></p>
        </li>
        <li>
            <p class="digit   counterWarning  ">: {{ minutes | twoDigits }} <span>M:</span></p>
        </li>
        <li >
            <p class="digit  counterWarning   ">: {{ seconds | twoDigits }} <span>S</span></p>
        </li> -->
    </div>
    <!-- <div v-else-if="days <1 && hours <1 &&  minutes <1 &&  seconds <1 ">
                <li v-if="days > 0">
                    <p class="digit">{{ days | twoDigits }} <span >{{ days > 1 ? 'D' : 'D' }}:</span></p>
                </li>
                <li><p class="digit">{{ hours | twoDigits }} <span>{{ hours > 1 ? 'H' : 'H' }}:</span></p></li>
                <li> <p class="digit">{{ minutes | twoDigits }} <span>M:</span></p></li>
                <li> <p class="digit">{{ seconds | twoDigits }} <span>S</span></p></li>
        </div> -->
    <div v-else>
      <div class="countdown">
        <p class="digit" style="">
          {{ days | twoDigits }}<span>{{ days > 1 ? " Days" : " Day" }}</span>
        </p>
        <p class="digit">
          {{ hours | twoDigits }} :{{ minutes | twoDigits }} :{{
            seconds | twoDigits
          }}
          s
        </p>
      </div>
      <!-- <li>
            <p class="digit">{{ days | twoDigits }} <span >{{ days > 1 ? 'D' : 'D' }} </span></p>
        </li>
        <li>
            <p class="digit">: {{ hours | twoDigits }} <span>{{ hours > 1 ? 'H' : 'H' }}</span></p>
        </li>
        <li>
            <p class="digit">: {{ minutes | twoDigits }} <span>M </span></p>
        </li>
        <li>
            <p class="digit">: {{ seconds | twoDigits }} <span>S</span></p>
        </li> -->
    </div>
  </ul>
</template>

<script>
let interval = null;
import moment from "moment";
// import Config from "../config";
export default {
  name: "vuejsCountDown",
  props: {
    deadline: String,
    customeStyle: String,

    stop: {
      type: Boolean,
    },
  },
  data() {
    return {
      now: null,
      date: null,
      diff: 0,
      countdown: "",
    };
  },
  created() {
    // Math.trunc(new Date().getTime() / 1000)

    this.now = Math.trunc(new Date().getTime() / 1000);
    //  var d = this.deadline
    //     d = new Date(d)
    //   var conv_date = d.toLocaleString("en-US", {
    //     timeZone: Config.CurrNttimezone ,
    //   });
    //console.log("deadline Created",this.deadline);
    var d = new Date(this.deadline);
    let offset = Math.abs(d.getTimezoneOffset() / 60);
    let utc = null;
    let nd = null;
    if (Intl.DateTimeFormat().resolvedOptions().timeZone != "Asia/Calcutta") {
      if (d.getTimezoneOffset() / 60 < 0) {
        // alert('- ve')
        nd = new Date(d.getTime() - 5.5 * 3600000 + offset * 3600000);
        this.ndDate = nd;
      } else {
        //alert('+ ve')

        nd = new Date(d.getTime() - 5.5 * 3600000 - offset * 3600000);
        this.ndDate = nd;
      }
    } else {
      utc = d.getTime() + d.getTimezoneOffset() * 60000;
      nd = new Date(utc + 3600000 * offset);
      this.ndDate = nd;
    }

    // var date1 = new Date();
    // var v = date1.getTimezoneOffset();
    //     console.log(v);
    //     console.log(d.getTimezoneOffset() / 60);
    //      console.log(d.getTime() - ( 5.5 * 3600000));
    //      console.log((d.getTime() - ( 5.5 * 3600000)) + 3 * 3600000);
    //      console.log(new Date((d.getTime() - ( 5.5 * 3600000)) + offset * 3600000));
    //     console.log(new Date (utc +  3600000 * offset ));
    let dateauc = moment(String(this.ndDate)).format("YYYY-MM-DD HH:mm");

    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;
    let endTime = deadline ? deadline : this.end;
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  //   mounted() {

  //   },
  updated() {
    this.now = Math.trunc(new Date().getTime() / 1000);

    // var d = this.deadline
    //     d = new Date(d)
    //   var conv_date = d.toLocaleString("en-US", {
    //     timeZone: Config.CurrNttimezone ,
    //   });
    //console.log("deadline Created",this.ndDate);
    let dateauc = moment(String(this.ndDate)).format("YYYY-MM-DD HH:mm");
    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;

    let endTime = deadline ? deadline : this.end;
    //  console.log(endTime);
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },

    minutes() {
      return Math.trunc(this.diff / 60) % 60;
    },

    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24;
    },

    days() {
      return Math.trunc(this.diff / 60 / 60 / 24);
    },
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        // Remove interval
        // console.log(value)
        this.blank_value = value;
        clearInterval(interval);
      }
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },
  destroyed() {
    clearInterval(interval);
  },
};
</script>
