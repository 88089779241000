<template>
  <div>
    <section>
      <div class="container">
        <div
          style="
            padding: 30px 30px;
            background-color: white;
            
            border-radius: 5px;
            margin-bottom: 50px;
          "
        >
          <div class="row">
            <div class="col-md-9">
              <div>
                <div class="mr-b-new">
                  <h4>PREMIUM AUCTIONS</h4>
                </div>
                <div>
                  <premiumauctions></premiumauctions>





                  <!-- <div class="row" style="">
                    <div
                      class="col-md-3 col-6"
                      style="padding: 10px"
                      v-for="(item, index) in bannerlist.result4"
                      :key="index"
                    >
                      <div>
                        <router-link
                          :to="{
                            name: 'AuctionList',
                            params: { brandName: item.elementName },
                          }"
                        >
                          <img :src="path + item.imageUrl" />
                        </router-link>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div
              class="col-md-3"
            v-if="bannerlist.result4[0].imageUrl"
            >
              <!-- <div>
                <router-link :to="item.Link">
                  <img
                    :src="path + item.imageUrl"
                    class="img-fluid"
                    style="width: 87%; margin: 0px auto; display: block"
                  />
                </router-link>
              </div> -->
              <div>
                <div class="o-video">
                 
                  <iframe :src="bannerlist.result5[0].imageUrl" allowfullscreen></iframe>
                </div>
                <div class="hUnv">
                  <img src="../../public/assets/images/right_top.png"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import userService from "../services/user.service";
import Config from "../config";
export default {
  data() {
    return {
      bannerlist: "",
      path: Config.BASE_URL_IMG,
    };
  },
  created() {
    this.getBanners();
  },
  methods: {
    getBanners() {
      userService
        .getBanners()
        .then((response) => {
          this.bannerlist = response.data.Data;
          //console.log(this.bannerlist);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
  },
};
</script>
