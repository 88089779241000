<template>
  <div>
    <div v-if="!items.length">
      <notfound :checkdata="initialLoading"></notfound>
    </div>
    <section style="padding: 60px 0px">
      <div>
        <div>
          <div class="container">
            <div class="row">
              <div
                class="col-md-3 fromto"
                v-for="(item, index) in items"
                :key="index"
              >
                <div>
                  <div>
                    <article class="pdtSliderhome">
                      <div>
                        <div>
                          <router-link
                            :to="{
                              name: 'CarDetails',
                              params: { url_key: item.aucName },
                            }"
                          >
                            <img
                              :src="
                                item.coverImage
                                  ? path + '/' + item.coverImage
                                  : item.vehImage1
                                  ? path + '/' + item.vehImage1
                                  : '/assets/images/NoImage.jpg'
                              "
                              class="img-fluid"
                              style="
                                object-fit: cover;
                                width: 100%;
                                height: 160px;
                              "
                            />
                          </router-link>
                        </div>
                        <div class="row">
                          <div class="col-10">
                            <div>
                              <router-link
                                :to="{
                                  name: 'CarDetails',
                                  params: { url_key: item.aucName },
                                }"
                              >
                                <h6 class="clip2line" style="margin-top: 8px">
                                  {{ item.brandName }} {{ item.modelName }}
                                </h6></router-link
                              >
                            </div>
                          </div>
                          <div class="col-2" style="padding: 2px 4px">
                            <div>
                              <div class="">
                                <div v-if="item.isWishlisted == 1">
                                  <div
                                    class="button-bidzzz active"
                                    @click="rmvfromWhishlist(item)"
                                  >
                                    <svg
                                      width="35px"
                                      height="25px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g fill="none" fill-rule="evenodd">
                                        <path
                                          class="heart-stroke"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="#0090E3"
                                        />
                                        <path
                                          class="heart-full"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="red"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M26,4 L30.6852129,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M2.314788,4 L7.00000086,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 10.314788 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M27,12 L33,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M0,12 L6,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 7 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M24,19 L28.6852129,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M4.314788,19 L9.00000086,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 14.314788 1)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div v-else>
                                  <div
                                    class="button-bidzzz"
                                    @click="addorRemove(item)"
                                  >
                                    <svg
                                      width="35px"
                                      height="25px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g fill="none" fill-rule="evenodd">
                                        <path
                                          class="heart-stroke"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="#0090E3"
                                        />
                                        <path
                                          class="heart-full"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="red"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M26,4 L30.6852129,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M2.314788,4 L7.00000086,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 10.314788 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M27,12 L33,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M0,12 L6,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 7 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M24,19 L28.6852129,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M4.314788,19 L9.00000086,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 14.314788 1)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="leftright10pad">
                          <div class="pdtHomelistrow odd">
                            <div class="pdtHomelistcolleft">Registration</div>
                            <div class="pdtHomelistcolright">
                              {{ item.vehRegNo | truncate(5) }} ****
                            </div>
                          </div>
                          <div class="pdtHomelistrow even">
                            <div class="pdtHomelistcolleft">Brand Name</div>
                            <div class="pdtHomelistcolright">
                              {{ item.brandName }}
                            </div>
                          </div>
                          <div class="pdtHomelistrow odd">
                            <div class="pdtHomelistcolleft">Year Of Make</div>
                            <div class="pdtHomelistcolright">
                              {{ item.manYear }}
                            </div>
                          </div>
                          <div class="pdtHomelistrow even">
                            <div class="pdtHomelistcolleft">Price Starts From</div>
                            <div class="pdtHomelistcolright">
                               {{ item.aucStartPrice }}
                            </div>
                          </div>
                          <div class="pdtHomelistrow even forUpDate">
                            <div class="start-in">Starts</div>
                            <div class="time-rem">
                              {{ item.aucDate | formatDate }} IST
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import userService from "../services/user.service";
import Config from "../config";
import axios from "axios";
import userinformation from "../models/localstorage";
import moment from "moment";
//import $ from 'jquery'
export default {
  name: "ProductCard",
  data() {
    return {
      lists: [],
      perPage: 10,
      currentPage: 1,
      path: Config.BASE_URL_IMG,
      currentlist: "",
      message: "",
      modalShow: false,
      bid: "",
      customerId: "",
      st: "",
      selectedUser: "",
      initialLoading: true,
    };
  },
  filters: {
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm a ");
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    items() {
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.lists.length;
    },
  },
  created() {
    this.curntUser = userinformation;

    // if (this.$store.state.auth.user)
    // {
    //   this.st = this.$store.state.auth.user[0].customerId;

    // }

    if (this.$store.state.auth.user) {
      if (localStorage.getItem("reloaded")) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
        //this.st = this.$store.state.auth.user[0].customerId
      }
      this.st = userinformation[0].customerId;
    }
    this.getCarList();
  },

  methods: {
    resetModal() {
      this.message = "";
      this.bid = "";
      this.message = null;
      this.bid = null;
    },
    getCarList() {
      if (this.st !== "undefind") {
        this.customerId = this.st;
      } else {
        this.customerId = "";
      }
      axios({
        method: "Get",
        url:
          Config.BASE_URL + "?sp=getUpcomingAuctions&custId=" + this.customerId,
      })
        .then((resp) => {
          this.lists = resp.data.Data;
          this.initialLoading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
    // getAuctionList(url_key) {
    //   if (url_key) {
    //     this.searchkey = url_key;
    //     if (this.st) {
    //       this.customerId = this.st;
    //       // console.log(this.customerId + "get auction customer id");
    //     } else {
    //       this.customerId = "";
    //     }
    //   }
    //   userService
    //     .getAuctionList(this.customerId, this.searchkey)
    //     .then((resp) => {
    //       this.lists = resp.data.Data;
    //       // console.log(this.lists);
    //     })
    //     .catch((err) => {
    //       console.log(err.response);
    //     });
    // },
    addorRemove(elem) {
      // console.log(this.$store.state.auth.user[0]);

      if (this.$store.state.auth.user) {
        this.addWhishlist(elem);
      } else {
        this.$router.push("/MyAccount").catch(() => {
          "carlist";
        });
      }
    },
    addWhishlist(elem) {
      //   console.log(userinformation[0].customerId);
      // console.log(elem);

      userService.addToWhishlist(this.st, elem).then((response) => {
        console.log(response.data.Message);
        this.message = " added to whishlist";
        this.getCarList();
      });
    },

    bidentry(item) {
      if (
        this.st &&
        this.selectedUser.aucMinBid !== "" &&
        this.selectedUser.aucMinBid !== null
      ) {
        let cusid = this.st;
        let bidamount = parseInt(this.selectedUser.aucMinBid);
        userService
          .addbid(item.aucId, cusid, bidamount)
          .then((response) => {
            // console.log(response.data.Message);
            this.message = response.data.Message;
            this.getCarList();
          })
          .catch((error) => {
            this.message =
              (error.response && error.response.data.Message) ||
              error.message ||
              error.toString();
          });
      } else {
        this.message = "Field is required";
      }
    },

    gotologin() {
      this.$router.push("/").catch(() => {
        "login";
      });
    },
    rmvfromWhishlist(item) {
      userService.rmvfromWhishlist(item, this.st).then((response) => {
        console.log(response.data.Message);

        this.getCarList();
      });
    },
    sendInfo(item) {
      if (!this.currentUser) {
        this.gotologin();
      } else {
        this.selectedUser = item;
      }
    },
  },
};
</script>

<style scoped>
.subBtnzz {
  background-image: linear-gradient(to bottom right, #1d1b2b, #004a6d);
  color: white;
  font-weight: 600;
}
.bidamountzz {
  font-size: 20px;
  font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
