import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue,BootstrapVueIcons  } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import imageZoom from "vue-image-zoomer";
import ProductZoomer from "vue-product-zoomer";
import VueEasyLightbox from "vue-easy-lightbox";
import Axios from "axios";
import VeeValidate from 'vee-validate';
import moment from 'moment'
import VueCookies from 'vue-cookies';
import FlashMessage from '@smartweb/vue-flash-message';
import VueHtmlToPaper from 'vue-html-to-paper';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
import VueTableDynamic from 'vue-table-dynamic'
import VueGoodTablePlugin from 'vue-good-table';
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

import { SlickList, SlickItem } from 'vue-slicksort';


// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import "vue-mailchimp-email-signup-form/dist/vue-mailchimp-email-signup-form.css";
import { VueMailchimpEmailSignupForm } from "vue-mailchimp-email-signup-form";
Vue.component('vfa-sortable-list', SlickList);
Vue.component('vfa-sortable-item', SlickItem);
Vue.component("vue-mailchimp-email-signup-form", VueMailchimpEmailSignupForm);
Vue.use(VueGoodTablePlugin);
Vue.use(VueTableDynamic)
Vue.use(VueHtmlToPaper, options);
Vue.use(FlashMessage);
Vue.use(moment);
Vue.use(VeeValidate);
Vue.use(VueEasyLightbox);
Vue.use(ProductZoomer);
Vue.use(Hooper, Slide);
Vue.use(imageZoom);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// Vue.use(VueCarousel);
Vue.use(VueCookies);
Vue.use(VueFileAgent,VueFileAgentStyles);

Vue.config.productionTip = false;

Vue.component("vechTypeSlider", require("./components/vechTypeSlider").default);
Vue.component("TypeSlider", require("./components/TypeSlider").default);
Vue.component("Slider", require("./components/Slider").default);
Vue.component("vendorlogin", require("./views/Pages/vendorLogin").default);
Vue.component("login", require("./views/Login").default);
Vue.component("Alert", require("./components/Alert").default);
Vue.component("Countdown", require("./components/Customcountdown").default);
Vue.component("PackageList", require("./components/PackageList").default);
Vue.component("HomeCountdown", require("./components/homeCountdown").default);
Vue.component("navbar", require("./components/Navbar.vue").default);
Vue.component("singleslider", require("./components/SingleSlider.vue").default);
Vue.component("banner", require("./components/Banner.vue").default);
Vue.component("brandarea", require("./components/BrandArea.vue").default);
Vue.component("midbanner", require("./components/midBanner.vue").default);
Vue.component("showmodel", require("./components/ShowModel.vue").default);
Vue.component("sidebar", require("./components/sidebar.vue").default);
Vue.component("carlist", require("./components/CarList.vue").default);
Vue.component("wishList", require("./components/WishList.vue").default);
Vue.component("mybidz", require("./components/MyBidz.vue").default);
Vue.component("mywinnings", require("./components/MyWinnings.vue").default);
Vue.component("changepassword", require("./views/Changepassword.vue").default);
Vue.component("comingsoon", require("./components/ComingSoon.vue").default);
Vue.component("notfound", require("./components/NotFound.vue").default);
Vue.component("recentlisting", require("./views/Pages/RecentListings.vue").default);
Vue.component("auctionhistory", require("./views/Pages/AuctionHistory.vue").default);
Vue.component("liveautions", require("./views/Pages/LiveAuctions.vue").default);
Vue.component("woncustomers", require("./views/Pages/WonCustomer.vue").default);
Vue.component("processwinners", require("./views/Pages/ProcessWinners.vue").default);
Vue.component("vendorchangepassword", require("./views/Pages/VendorChangePassword.vue").default);
Vue.component("mywinnings", require("./views/MyWinnings.vue").default);
// Vue.component("processwinningscustomer", require("./views/ProcessWinningsCustomer.vue").default);
Vue.component("addvehicle", require("./views/Pages/AddVehicle.vue").default);
Vue.component("sidebar-dashboard", require("./views/Pages/Sidbar-Dashboard.vue").default);
Vue.component("sidebar-dashboard-customer", require("./components/Sidbar-DashboardCustomer.vue").default);
Vue.component("loader", require("./components/Loader.vue").default);
Vue.component("hotauctions", require("./components/HotAuctions.vue").default);
Vue.component("premiumauctions", require("./components/PremiumAuctions.vue").default);
Vue.component("upcommingauctions", require("./components/UpcommingAuction.vue").default);
Vue.component("successMessage", require("./components/successMessage.vue").default);


Vue.component(
  "customcarousel",
  require("./components/CustomCarousel.vue").default
);

Vue.component(
  "selectioncontainer",
  require("./components/SelectionContainer.vue").default
);
Vue.component(
  "homeloginsection",
  require("./components/HomeLoginselection.vue").default
);
Vue.component("foot", require("./components/Foot.vue").default);


 Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}
new Vue({
  router,
  store,
  render: (h) => h(App), 

}).$mount("#app");



