

<template>
    <ul class="vuejs-countdown">
         <div v-if=" days<= 0 && hours <=0 && minutes < 10 && seconds >0  " >
                 <li v-if="days > 0">
            <p class="digit counter">{{ days | twoDigits }} <span >{{ days > 1 ? 'D' : 'D' }}:</span></p>
        </li>
        <li v-if="hours > 0">
            <p class="digit digit1 counter">{{ hours | twoDigits }} <span>{{ hours > 1 ? 'H' : 'H' }}:</span></p>
        </li>
        <li>
            <p class="digit digit1 counter  ">{{ minutes | twoDigits }} <span>M:</span></p>
        </li>
        <li >
            <p class="digit  digit1 counter   ">{{ seconds | twoDigits }} <span>S</span></p>
        </li>
       
        </div>
        <div v-else>
                 <li v-if="days > 0">
            <p class="counter">{{ days | twoDigits }} <span >{{ days > 1 ? 'D' : 'D' }}:</span></p>
        </li>
        <li>
            <p class="counter">{{ hours | twoDigits }} <span>{{ hours > 1 ? 'H' : 'H' }}:</span></p>
        </li>
        <li>
            <p class="counter">{{ minutes | twoDigits }} <span>M:</span></p>
        </li>
        <li>
            <p class="counter">{{ seconds | twoDigits }} <span>S</span></p>
        </li>
        </div>

       
    </ul>
</template>

<!-- <script>
let interval = null;
import moment from 'moment'
export default {
    name: 'vuejsCountDown',
    props: {
        deadline: String,
        end: {
            type: String
        },
        stop: {
            type: Boolean
        }
    },
    data() {
        return {
            now: null,
            date: null,
            diff: 0
        }
    },
    created() {

        this.now = Math.trunc(new Date().getTime() / 1000);
       
         let dateauc=   moment(String(this.deadline)).format('YYYY-MM-DD HH:mm');         
        if (!dateauc && !this.end) {
           throw new Error("Missing props 'deadline' or 'end'");
            
        }
        let deadline = dateauc;

        let endTime = deadline ? deadline : this.end;
       //  console.log(endTime);
        this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
         
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline' or 'end'");
        }

        interval = setInterval(() => {
            this.now = Math.trunc((new Date()).getTime() / 1000);
        }, 1000);
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },

        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },

        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24
        },

        days() {
            return Math.trunc(this.diff / 60 / 60 / 24)
        }
    },
    watch: {
        now(value) {
            this.diff = this.date - this.now;
            if(this.diff <= 0 || this.stop){
                this.diff = 0;
                // Remove interval
               // console.log(value)
               this.blank_value =value;
                clearInterval(interval);
            }
        }
    },
    filters: {
        twoDigits(value) {
            if ( value.toString().length <= 1 ) {
                return '0'+value.toString()
             
            }
            return value.toString()
            
        }     
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script> -->


<script>
let interval = null;
import moment from "moment";
// import Config from "../config";
export default {
  name: "vuejsCountDown",
  props: {
    deadline: String,
    customeStyle: String,

    stop: {
      type: Boolean,
    },
  },
  data() {
    return {
      now: null,
      date: null,
      diff: 0,
      countdown: "",
    };
  },
  created() {
    // Math.trunc(new Date().getTime() / 1000)

    this.now = Math.trunc(new Date().getTime() / 1000);
    //  var d = this.deadline
    //     d = new Date(d)
    //   var conv_date = d.toLocaleString("en-US", {
    //     timeZone: Config.CurrNttimezone ,
    //   });

    var d = new Date(this.deadline);
    let offset = Math.abs(d.getTimezoneOffset() / 60);
    let utc = null;
    let nd = null;
    if (Intl.DateTimeFormat().resolvedOptions().timeZone != "Asia/Calcutta") {
      if (d.getTimezoneOffset() / 60 < 0) {
        // alert('- ve')
        nd = new Date(d.getTime() - 5.5 * 3600000 + offset * 3600000);
        this.ndDate = nd;
      } else {
        //alert('+ ve')

        nd = new Date(d.getTime() - 5.5 * 3600000 - offset * 3600000);
        this.ndDate = nd;
      }
    } else {
      utc = d.getTime() + d.getTimezoneOffset() * 60000;
      nd = new Date(utc + 3600000 * offset);
      this.ndDate = nd;
    }

    // var date1 = new Date();
    // var v = date1.getTimezoneOffset();
    //     console.log(v);
    //     console.log(d.getTimezoneOffset() / 60);
    //      console.log(d.getTime() - ( 5.5 * 3600000));
    //      console.log((d.getTime() - ( 5.5 * 3600000)) + 3 * 3600000);
    //      console.log(new Date((d.getTime() - ( 5.5 * 3600000)) + offset * 3600000));
    //     console.log(new Date (utc +  3600000 * offset ));
    let dateauc = moment(String(this.ndDate)).format("YYYY-MM-DD HH:mm");

    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;
    let endTime = deadline ? deadline : this.end;
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  //   mounted() {

  //   },
  updated() {
    this.now = Math.trunc(new Date().getTime() / 1000);

    // var d = this.deadline
    //     d = new Date(d)
    //   var conv_date = d.toLocaleString("en-US", {
    //     timeZone: Config.CurrNttimezone ,
    //   });

    let dateauc = moment(String(this.ndDate)).format("YYYY-MM-DD HH:mm");
    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;

    let endTime = deadline ? deadline : this.end;
    //  console.log(endTime);
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },

    minutes() {
      return Math.trunc(this.diff / 60) % 60;
    },

    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24;
    },

    days() {
      return Math.trunc(this.diff / 60 / 60 / 24);
    },
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        // Remove interval
        // console.log(value)
        this.blank_value = value;
        clearInterval(interval);
      }
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },
  destroyed() {
    clearInterval(interval);
  },
};
</script>