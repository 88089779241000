<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container ">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>

              <div class="col-lg-9">
                <div v-if="!items.length">
                  <notfound :checkdata="initialLoading"></notfound>
                  <!-- <loader></loader> -->
                </div>
                <div v-else>
                  <section style="padding: 60px 0px">
                    <div class="container bidz-container-list">
                      <div
                        class="pdtListingWrap"
                        v-for="(item,index) in items"
                        :key="index" 
                      >
                        <div class="row">
                          <div class="col-md-2">
                            <div class="bidlistImagewrap">
                              <img
                                :src="path + '/' + item.vehImage1"
                                class="img-fluid img-responsive border5px"
                              />
                            </div>
                          </div>
                          <div class="col-md-7 bidborderright">
                            <div class="row">
                              <div class="bidlistDetailswrapwidth85">
                                <div class="bidlistDetailswrap">
                                  <a href="#"
                                    ><h4>
                                      {{ item.brandName }}
                                      <span class="cModalname">
                                        {{ item.modelName }}
                                      </span>
                                    </h4></a
                                  >
                                </div>
                              </div>
                              <div class="bidlistDetailswrapwidth15">
                                <div>
                                  <button
                                    class="favoritesListpdt js-btn-tooltip"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    data-custom-class="tooltip-primary"
                                    title="Add to Favorites"
                                    @click="addToWhishlist()"
                                  >
                                    <i class="far fa-heart"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div class="row hidinglast2on768-991">
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-tachometer-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                      <span>KM Clocked</span>
                                      <p class="fontWeightbidlist">
                                        {{ item.kmClocked }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="fas fa-map-marker-alt pdtListico"
                                    ></i>
                                    <div class="pdtListicon">
                                      <!-- <span>Location</span> -->
                                      <p
                                        class="fontWeightbidlist main-tile-location"
                                      >
                                        {{ item.locName }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="far fa-calendar-alt pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                      <span>End Date</span>
                                      <p class="fontWeightbidlist greenIcon">
                                        {{ item.aucMatDate }}  IST
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <i
                                      class="far fa-clock pdtListico greenIcon"
                                    ></i>
                                    <div class="pdtListicon">
                                      <span>Time Left</span>
                                      <p class="fontWeightbidlist greenIcon">
                                        {{ item.aucMatDate }}  IST
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <span>Min. Increment</span>
                                      <p class="fontWeightbidlist">
                                        {{ item.aucMinBid | currency }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <p class="numberplate">
                                        {{ item.vehRegNo | truncate(5) }} ****
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <a href=""
                                        ><div class="bidNumberbtn">
                                          {{ item.bidzCount }}
                                        </div></a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="col-md-3 col-sm-3 bidlistwidth50p hideon768-991 bidlistwidthfor768-991"
                              >
                                <div class="">
                                  <div>
                                    <div class="text-center">
                                      <router-link to="Details"
                                        ><div class="bidViewDetails">
                                          View Details
                                        </div></router-link
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="text-center">
                              <div class="marginbidtop">
                                <span>CURRENT PRIZE</span>
                                <h4>
                                  {{ item.latestBidAmount | currency }}
                                </h4>
                                <div class="marginbidtop">
                                  <router-link to="Details" class="bidBtn"
                                    >BID NOW</router-link
                                  >
                                </div>
                              </div>
                              <div>
                                <div class="row">
                                  <div class="col">
                                    <div>
                                      <a href=""
                                        ><div class="bidzNumberz">
                                          {{ item.bidzCount }}
                                        </div></a
                                      >
                                    </div>
                                  </div>
                                  <div class="col">
                                    <div>
                                      <router-link to="Details"
                                        ><div class="detailsButtonz">
                                          Details
                                        </div></router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="overflow-auto">
                        <div class="mt-3">
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="center"
                            pills
                            first-number
                            last-number
                          ></b-pagination>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import userinformation from "../models/localstorage";
//import userService from '../services/user.service';
import Config from "../config";
import axios from "axios";
// import Loader from './Loader.vue';
export default {
  // components: { Loader },
  name: "ProductCard",

  data() {
    return {
      lists: [],
      perPage: 10,
      currentPage: 1,
      path: Config.BASE_URL_IMG,
      currentlist: "",
      initialLoading: true,
    };
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },

    items() {
      return this.lists.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    totalRows() {
      return this.lists.length;
    },
  },
  mounted() {
    this.st = userinformation;
    this.getCarList();
  },
  methods: {
    getCarList: function () {
      let tokens = this.st[0].token;
      let customerId = this.st[0].customerId;
      axios({
        method: "Get",
        url: Config.BASE_URL + "?sp=getCustomerWinnings&custId=" + customerId,
        headers: { token: tokens },
      })
        .then((resp) => {
          this.lists = resp.data.Data;

          this.initialLoading = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.initialLoading = false;
        });
    },
  },
};
</script>

<style>
.btn-invoice:hover
{
  color:white!important;
}



</style>
