<template>
  <div>
    <div v-if="lists.length > 0">
      <carousel
        :responsive="{
          0: {
            items: 2,
            nav: false,
            dots: false,
            loop: true,
            autoplay: false,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            margin: 20,
          },
          600: {
            items: 5,
            nav: false,
            dots: false,
            loop: true,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
            margin: 80,
          },
        }"
      >
        <div v-for="(list, index) in lists" :key="index">
          <router-link 
            :to="{
              name: 'AuctionList',
              params: { vehTypeName: list.vehTypeName },
            }"
          >
            <img :src="path + list.imageUrl" />
          </router-link>
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import Config from "../config";
export default {
  components: {
    carousel,
  },
  props: {
    lists: [],
    slidesno: Number,
  },
  data: function () {
    return {
      path: Config.BASE_URL_IMG,
    };
  },



};
</script>
<style scoped>
.container {
  font-family: arial;
  font-size: 24px;
  margin: 50px;
  width: 350px;
  height: 200px;
  /* Setup */
  position: relative;
}

.child {
  /* Center vertically and horizontally */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}
</style>
