<template>
  <div class="mainbody">
    <div class="card">
      <div class="innercr">
        <i class="checkmark">✓</i>
      </div>
      <h1>Success</h1>
      <p>
        Your bid of Rs {{ bidAmount | currency }} has been successfully
        submitted.
      </p>
    </div>
  </div>
</template>
<script>
import Config from '../config';
export default {
  props: {
    // userName: String,
    aucName: String,
    bidAmount: Boolean,
  },
  // data() {
  //   return {
  //     userName: "Rahul Ramachandran",
  //     bidAmount: 20000,
  //     aucName: "kmmefmsmdfmdfefmmfkeelsdgsf",
  //   };
  // },

  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
  },
};
</script>
<style scoped>
.mainbody {
  text-align: center;
  /* padding: 40px 0;
  background: #ebf0f5; */
}
h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}
i {
  color: #9abc66;
  font-size: 100px;
  line-height: 134px;
  margin-right: 15px;
}
.card {
  /* background: white; */
  padding: 3px;
  /* border-radius: 24px; */
  /* box-shadow: 0 2px 3px #c8d0d8; */
  /* display: inline-block; */
  margin: 0 auto;
}
.innercr {
  border-radius: 202px;
  height: 135px;
  width: 144px;
  background: rgb(248, 250, 245);
  margin: 0px auto;
}
</style>
