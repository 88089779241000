export default class VechDetails {
    constructor(brandName,modelName,vehRegNo,locId,vehPrice,vehMinPrice,
      kmClocked,manYear,underWarantee) {
      this.brandName = brandName;
      this.modelName = modelName;
      // this.FuelType = FuelType;
      this.vehRegNo = vehRegNo;
      this.locId = locId;
      this.vehPrice = vehPrice;
      this.vehMinPrice = vehMinPrice;
      this.kmClocked = kmClocked;
      this.manYear = manYear;
      // this.History = History;
      // this.TyreCondition = TyreCondition;
      // this.Exterior = Exterior;
      // this.Interior = Interior;
      this.underWarantee = underWarantee;
     
    }
  }
