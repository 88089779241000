<template>
  <!-- Begin Modal Area -->
  <div class="modal fade modal-wrapper" id="exampleModalCenter">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-inner-area sp-area row">
            <div class="col-lg-5">
              <div class="sp-img_area">
                <div
                  class="sp-img_slider slick-img-slider uren-slick-slider"
                  data-slick-options='{
                                    "slidesToShow": 1,
                                    "arrows": false,
                                    "fade": true,
                                    "draggable": false,
                                    "swipe": false,
                                    "asNavFor": ".sp-img_slider-nav"
                                    }'
                >
                  <div class="single-slide red">
                    <img
                      src="/assets/images/product/large-size/1.jpg"
                      alt="Product Image"
                    />
                  </div>
                  <div class="single-slide orange">
                    <img
                      src="/assets/images/product/large-size/2.jpg"
                      alt="Product Image"
                    />
                  </div>
                  <div class="single-slide brown">
                    <img
                      src="/assets/images/product/large-size/3.jpg"
                      alt="Product Image"
                    />
                  </div>
                  <div class="single-slide umber">
                    <img
                      src="/assets/images/product/large-size/4.jpg"
                      alt="Product Image"
                    />
                  </div>
                  <div class="single-slide black">
                    <img
                      src="/assets/images/product/large-size/5.jpg"
                      alt="Product Image"
                    />
                  </div>
                  <div class="single-slide golden">
                    <img
                      src="/assets/images/product/large-size/6.jpg"
                      alt="Product Image"
                    />
                  </div>
                </div>
                <div
                  class="sp-img_slider-nav slick-slider-nav uren-slick-slider slider-navigation_style-3"
                  data-slick-options='{
                                   "slidesToShow": 4,
                                    "asNavFor": ".sp-img_slider",
                                   "focusOnSelect": true,
                                   "arrows" : true,
                                   "spaceBetween": 30
                                  }'
                  data-slick-responsive='[
                                    {"breakpoint":1501, "settings": {"slidesToShow": 3}},
                                    {"breakpoint":992, "settings": {"slidesToShow": 4}},
                                    {"breakpoint":768, "settings": {"slidesToShow": 3}},
                                    {"breakpoint":575, "settings": {"slidesToShow": 2}}
                                ]'
                >
                  <div class="single-slide red">
                    <img
                      src="/assets/images/product/small-size/1.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                  <div class="single-slide orange">
                    <img
                      src="/assets/images/product/small-size/2.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                  <div class="single-slide brown">
                    <img
                      src="/assets/images/product/small-size/3.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                  <div class="single-slide umber">
                    <img
                      src="/assets/images/product/small-size/4.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                  <div class="single-slide black">
                    <img
                      src="/assets/images/product/small-size/5.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                  <div class="single-slide golden">
                    <img
                      src="/assets/images/product/small-size/6.jpg"
                      alt=" Product Thumnail"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-7 col-lg-6">
              <div class="sp-content">
                <div class="sp-heading">
                  <h5><a href="#">Dolorem odio provident ut nihil</a></h5>
                </div>
                <div class="rating-box">
                  <ul>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li><i class="ion-android-star"></i></li>
                    <li class="silver-color">
                      <i class="ion-android-star"></i>
                    </li>
                    <li class="silver-color">
                      <i class="ion-android-star"></i>
                    </li>
                  </ul>
                </div>
                <div class="price-box">
                  <span class="new-price new-price-2">$194.00</span>
                  <span class="old-price">$241.00</span>
                </div>
                <div class="sp-essential_stuff">
                  <ul>
                    <li>Brands <a href="javascript:void(0)">Buxton</a></li>
                    <li>
                      Product Code:
                      <a href="javascript:void(0)">Product 16</a>
                    </li>
                    <li>Reward Points: <a href="javascript:void(0)">100</a></li>
                    <li>
                      Availability:
                      <a href="javascript:void(0)">In Stock</a>
                    </li>
                    <li>
                      EX Tax:
                      <a href="javascript:void(0)"><span>$453.35</span></a>
                    </li>
                    <li>
                      Price in reward points:
                      <a href="javascript:void(0)">400</a>
                    </li>
                  </ul>
                </div>
                <div class="color-list_area">
                  <div class="color-list_heading">
                    <h4>Available Options</h4>
                  </div>
                  <span class="sub-title">Color</span>
                  <div class="color-list">
                    <a
                      href="javascript:void(0)"
                      class="single-color active"
                      data-swatch-color="red"
                    >
                      <span class="bg-red_color"></span>
                      <span class="color-text">Red (+$150)</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="single-color"
                      data-swatch-color="orange"
                    >
                      <span class="burnt-orange_color"></span>
                      <span class="color-text">Orange (+$170)</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="single-color"
                      data-swatch-color="brown"
                    >
                      <span class="brown_color"></span>
                      <span class="color-text">Brown (+$120)</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="single-color"
                      data-swatch-color="umber"
                    >
                      <span class="raw-umber_color"></span>
                      <span class="color-text">Umber (+$125)</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="single-color"
                      data-swatch-color="black"
                    >
                      <span class="black_color"></span>
                      <span class="color-text">Black (+$125)</span>
                    </a>
                    <a
                      href="javascript:void(0)"
                      class="single-color"
                      data-swatch-color="golden"
                    >
                      <span class="golden_color"></span>
                      <span class="color-text">Golden (+$125)</span>
                    </a>
                  </div>
                </div>
                <div class="quantity">
                  <label>Quantity</label>
                  <div class="cart-plus-minus">
                    <input class="cart-plus-minus-box" value="1" type="text" />
                    <div class="dec qtybutton">
                      <i class="fa fa-angle-down"></i>
                    </div>
                    <div class="inc qtybutton">
                      <i class="fa fa-angle-up"></i>
                    </div>
                  </div>
                </div>
                <div class="uren-group_btn">
                  <ul>
                    <li>
                      <a href="cart.html" class="add-to_cart">Cart To Cart</a>
                    </li>
                    <li>
                      <a href="cart.html"
                        ><i class="ion-android-favorite-outline"></i
                      ></a>
                    </li>
                    <li>
                      <a href="cart.html"
                        ><i class="ion-ios-shuffle-strong"></i
                      ></a>
                    </li>
                  </ul>
                </div>
                <div class="uren-tag-line">
                  <h6>Tags:</h6>
                  <a href="javascript:void(0)">Ring</a>,
                  <a href="javascript:void(0)">Necklaces</a>,
                  <a href="javascript:void(0)">Braid</a>
                </div>
                <div class="uren-social_link">
                  <ul>
                    <li class="facebook">
                      <a
                        href="https://www.facebook.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Facebook"
                      >
                        <i class="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li class="twitter">
                      <a
                        href="https://twitter.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Twitter"
                      >
                        <i class="fab fa-twitter-square"></i>
                      </a>
                    </li>
                    <li class="youtube">
                      <a
                        href="https://www.youtube.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Youtube"
                      >
                        <i class="fab fa-youtube"></i>
                      </a>
                    </li>
                    <li class="google-plus">
                      <a
                        href="https://www.plus.google.com/discover"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Google Plus"
                      >
                        <i class="fab fa-google-plus"></i>
                      </a>
                    </li>
                    <li class="instagram">
                      <a
                        href="https://rss.com/"
                        data-toggle="tooltip"
                        target="_blank"
                        title="Instagram"
                      >
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Area End Here -->
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
