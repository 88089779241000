<template>
  <div style="margin-top: 50px" v-if="bannerlist">
    <Slider :lists="bannerlist" :slidesno="1"></Slider>
    <div>
      <div class="carousel-text home-slider-content">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div>
                <div>
                  <div class="tab-slider-form" v-if="!loggedIn && guestid">
                    <b-tabs content-class="form-slider-s">
                      <b-tab title="Customer" :active="activeTabCustomer">
                        <div>
                          <div class="text-center">
                            <h5 class="form-head-style">Login as Customer</h5>
                          </div>
                          <div>
                            <login></login>
                          </div>
                        </div>
                      </b-tab>
                      <!-- <b-tab title="Vendor" :active="activeTabVendor">
                        <div>
                          <div class="text-center">
                            <h5 class="form-head-style">Login as Vendor</h5>
                          </div>
                        </div>
                        <div>
                          <vendorlogin></vendorlogin>
                        </div>
                      </b-tab> -->
                    </b-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8"></div>
          </div>
        </div>
      </div>
    </div>
    <section class="looking-for-car">
      <div class="container">
        <div
          style="
            padding: 40px 70px 50px 70px;
            background-color: white;
            border-radius: 5px;
            margin-top: -40px;
            position: relative;
            z-index: 110;
          "
        >
          <div class="home-bigSearch">
            <div class="">
              <h4 class="noMar">
                <i style="color: #0094da" class="fas fa-car"></i> LOOKING FOR A
                <span style="color: #0094da">CAR?</span>
              </h4>
            </div>
            <div class="for-big-screen hm-form_area">
              <form action="#" class="hm-searchbox">
                <input
                  type="text"
                  placeholder="Enter your search key ..."
                  v-model="search"
                />

                <router-link
                  :to="{ path: '/AuctionList', query: { search: search } }"
                  ><div class="header-search_btn">
                    <i class="ion-ios-search-strong"><span>Search</span></i>
                  </div></router-link
                >
              </form>
            </div>
          </div>
        </div>   
      </div>
    </section>

    <template>
      <div class="info-OutWrap">
        <div v-for="Setting in SettingList" :key="Setting.settingsId">
          <div
            class="commonInfoStrip card text-center"
            v-if="
              Setting.sKey == 'commonInfoStripEn' ||
              Setting.sKey == 'commonInfoStripMal'
            "
          >
            <div v-if="Setting.sKey == 'commonInfoStripEn'" class="warFLEX">
              <img src="https://cdn-icons-png.flaticon.com/512/564/564619.png" class="warIMG"><p class="commonInfoStripEn">
                {{ Setting.sValue }}
              </p>
            </div>
            <div v-if="Setting.sKey == 'commonInfoStripMal'" class="warFLEX">
              <img src="https://cdn-icons-png.flaticon.com/512/564/564619.png" class="warIMG"><p class="commonInfoStripMal">
                {{ Setting.sValue }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
// import carousel from "vue-owl-carousel";
//import Alert from "../components/Alert.vue";
import User from "../models/user";
import userService from "../services/user.service";
import Config from "../config";
export default {
  // components: { Alert },
  data() {
    return {
      search: "",
      bannerlist: [],
      path: Config.BASE_URL_IMG,

      user: new User("", ""),
      loading: false,
      guestid: "",
      message: "",
      activeTabVendor: false,
      activeTabCustomer: true,
      userType: "",
      SettingList: [],
    };
  },
  watch: {
    changed_url(search) {
      this.search = search;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    // if (this.loggedIn) {
    //   this.$router.push('/MyAccount').catch(()=>{"login"});
    // }
    this.getBanners();
    this.getSettings();
  },
  mounted() {
    this.guestid = localStorage.getItem("guestid");
    localStorage.removeItem("guestid");

    this.userType = localStorage.getItem("userType");
    if (this.userType !== undefined) {
      if (this.userType == "customer") {
        this.activeTabCustomer = true;
        this.activeTabVendor = false;
      } else if (this.userType == "vendor") {
        this.activeTabCustomer = false;
        this.activeTabVendor = true;
      }
    }
  },

  methods: {
    getBanners() {
      userService
        .getBanners()
        .then((response) => {
          this.bannerlist = response.data.Data.result1;
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },

    getSettings() {
      userService
        .getSettings()
        .then((response) => {
          this.SettingList = response.data.Data;
          console.log(this.SettingList);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.form-group {
  margin-bottom: 0px !important;
}
</style>
