<template>
  <div>
    <div v-if="!ListAuction">
      <!-- <notfound></notfound> -->
    </div>

    <div class="container mp-sec" v-else-if="ListAuction.length">
      <div class="text-center">
        <h3 class="mb-30">Auctions Ends Soon</h3>
      </div>

      <carousel
        :responsive="{
          0: {
            items: 1,
            nav: false,
            dots: false,
            loop: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
          },
          600: {
            items: 3,
            nav: false,
            dots: false,
            loop: false,
            autoplay: true,
            autoplayTimeout: 4000,
            autoplayHoverPause: true,
          },
        }"
      >
        <div class="" v-for="(list, index) in ListAuction" :key="index">
          <div class="new-main-tile">
            <div>
              <router-link
                :to="{
                  name: 'CarDetails',
                  params: { url_key: list.aucName },
                }"
              >
                <img :src="list.coverImage ? (imags + '/' + list.coverImage) : list.vehImage1 ? (imags + '/' + list.vehImage1) : '/assets/images/Noimage.jpg' " style="width: 100%"
              /></router-link>
              <!-- <a href="/Auction">
                    <img
                     :src="imags + list.vehImage1"
                    style="width: 100%"
                  />
                  </a> -->
            </div>
            <div class="content-wrap-new-tile">
              <div class="row">
                <div class="col-8">
                  <div class="tile-set-l">
                    <router-link
                      :to="{
                        name: 'CarDetails',
                        params: { url_key: list.aucName },
                      }"
                    >
                      <h5>
                        {{ list.brandName }}
                        <span class="cModalname">
                          {{ list.modelName }}
                        </span>
                      </h5></router-link
                    >
                    <!-- <h5><a href="#">{{ list.brandName }} {{ list.modelName }}</a></h5> -->
                    <p class="text-Overtflow">Model : {{ list.manYear }}</p>
                  </div>
                </div>
                <div class="col-4">
                  <div>
                    <div class="new-tile-bid-btn">
                      <router-link
                        :to="{
                          name: 'CarDetails',
                          params: { url_key: list.aucName },
                        }"
                      >
                        BID NOW
                      </router-link>
                    </div>
                    <!-- <button class="new-tile-bid-btn">BID NOW</button> -->
                  </div>
                </div>
              </div>
              <div>
                <hr />
              </div>
              <div class="tile-content-n">
                <div>
                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <p class="text-Overtflow">
                          <i class="fa fa-road" aria-hidden="true"></i>
                          {{ list.kmClocked }} KM
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <p class="text-Overtflow">
                          <i class="fa fa-gavel" aria-hidden="true"></i>
                          {{ list.bidzCount }} BIDS
                        </p>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div>
                        <p class="text-Overtflow">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          {{ list.locName }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div>
                <button class="timer-button">
                  <HomeCountdown
                    class="test-btnz"
                    :deadline="list.actualMaturityDate"
                  ></HomeCountdown>
                </button>
              </div>
            </div>
          </div>
         
        </div>
      </carousel>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import userService from "../services/user.service";
import Config from "../config";
import moment from "moment";

export default {
  components: { carousel },
  data() {
    return {
      ListAuction: "",
      imags: "",
    };
  },
  filters: {
    dateISO(value) {
      if (value) {
        var d = new Date(value);
        //  d = d.toLocaleString("en-US", { timeZone:  Config.CurrNttimezone });
        // var date = new Date(d);
        return d.toISOString();
      }
    },
    truncate: function (data, num) {
      const reqdString = data.split("").slice(0, num).join("");
      return reqdString;
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY h:mm asss");
      }
    },
  },
  created() {
    this.todaysAuction();
  },
  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];
    }
    this.todaysAuction();
  },
  methods: {
    todaysAuction() {
      if (this.st) {
        this.customerId = this.st.customerId;
      } else {
        this.customerId = "";
      }
      userService
        .todaysAuction(this.customerId)
        .then((response) => {
          this.ListAuction = response.data.Data;
          this.imags = Config.BASE_URL_IMG;
          //  console.log(this.ListAuction);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
  },
  gotoAuction() {
    this.$router.push("/Auction").catch(() => {
      "details/auctionbyname";
    });
  },
};
</script>
