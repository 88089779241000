<template>
  <div>
    <div>
      <section
        class="loginformSection"
        style="background-image: url(assets/images/login-back.jpg)"
      >
        <div class="row nomp">
          <div class="col-md-12 nomp">
            <div class="row nomp">
              <div class="col-md-6 nomp">
                <div>
                  <div class="loginWelcome">
                    <!-- <h1>Welcome !</h1> -->
                    <h5>
                      Please Sign up to continue!
                      <br /><br />
                      Do you wish to buy a car?<br />
                      Sign up as a customer to get the best deals.<br /><br />
                      <a
                        href="/Register"
                        style="
                          color: #525252;
                          background-color: #ffffff;
                          padding: 7px;
                          border-radius: 4px;
                          font-size: 15px;
                        "
                        >Go to customer registration</a
                      >
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6 backwhite nomp" v-if="!item">
                <div>
                  <div class="card card-container">
                    <form name="form" @submit.prevent="handleRegister">
                      <div v-if="!successful">
                        <h5 class="b-b-head">Register as Vendor </h5>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="username">Full Name</label>
                              <input
                                v-model="user.username"
                                v-validate="'required|min:3|max:20'"
                                type="text"
                                class="form-control"
                                name="username"
                                placeholder="Full Name"
                              />
                              <div
                                v-if="submitted && errors.has('username')"
                                class="alert-danger"
                              >
                                {{ errors.first("username") }}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div>
                              <div class="form-group">
                                <label for="email">Email</label>
                                <input
                                  v-model="user.email"
                                  v-validate="'required|email|max:50'"
                                  type="email"
                                  class="form-control"
                                  name="email"
                                  placeholder="Email"
                                />
                                <div
                                  v-if="submitted && errors.has('email')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("email") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <div class="form-group">
                                <label for="phone">Phone</label>
                                <VuePhoneNumberInput
                                  v-model="yourValue"
                                  :required="true"
                                  :error="iserror"
                                  color="red"
                                  :noExample="true"
                                  fetch-country
                                  @update="resultsExample = $event"
                                />
                                <div
                                  v-if="submitted && phoneMessageError"
                                  class="alert-danger"
                                >
                                  {{ phoneMessageError }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div>
                              <div class="form-group">
                                <label for="password">Password</label>
                                <input
                                  v-model="user.password"
                                  v-validate="'required|min:6|max:40'"
                                  :type="passwordType"
                                  class="form-control"
                                  name="password"
                                  placeholder="Password"
                                />
                                <div class="control passwordTypeCls">
                                  <div class="button" @click="toggleShow">
                                    <span class="icon is-small is-right">
                                      <i
                                        class="fas"
                                        :class="{
                                          'fa-eye-slash': showPassword,
                                          'fa-eye': !showPassword,
                                        }"
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  v-if="submitted && errors.has('password')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("password") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6">
                            <div>
                              <div class="form-group">
                                <label for="gstnumber">GST Number</label>
                                <input
                                  v-model="user.gstnumber"
                                  v-validate="'required|min:3|max:20'"
                                  type="text"
                                  class="form-control"
                                  name="gstnumber"
                                  placeholder="GST Number"
                                />
                                <div
                                  v-if="submitted && errors.has('gstnumber')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("gstnumber") }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div>
                              <div class="form-group">
                                <label for="idproof">ID Proof</label>
                                <input
                                  v-validate="'required'"
                                  type="file"
                                  class="form-control"
                                  name="idproof"
                                  placeholder="idproof"
                                  @change="onFileChange"
                                />
                                <label
                                  v-if="imagesmessage"
                                  for="imagesmessage"
                                  class="errorAlert"
                                  >{{ imagesmessage }}</label
                                >
                                <div
                                  v-if="submitted && errors.has('idproof')"
                                  class="alert-danger"
                                >
                                  {{ errors.first("idproof") }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--GST Number  -->

                        <!-- ID Proof -->

                        <div class="tacbox">
                          <input
                            id="checkbox"
                            type="checkbox"
                            v-model="user.termsChecked"
                            name="check"
                            v-validate="'required'"
                            class="check"
                            key=""
                          />

                          <label for="checkbox">
                            By clicking 'Accept' you are agreeing to our
                            <a href="/SellersTerms" target="_blank"
                              >Terms and Conditions</a
                            >.</label
                          >
                        </div>
                        <div
                          style="
                            margin: 5px 0px 10px 0px;
                            padding: 4px;
                            border-radius: 4px;
                          "
                          v-if="submitted && errors.has('check')"
                          class="alert-danger"
                        >
                          {{ errors.first("check") }}
                        </div>

                        <div class="form-group" style="margin-bottom: 0px">
                          <button
                            class="btn btn-primary btn-block loginBtn"
                            :disabled="loading"
                          >
                            <span
                              v-show="loading"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Sign Up</span>
                          </button>
                        </div>
                      </div>
                    </form>

                    <div class="form-group">
                      <label for="password">
                        Already registered?  <a class="ar-pl-log" 
                          > <span @click="removeguestid(0)">Please Login</span></a
                        ></label
                      >
                      <div style="text-align: center">
                        <a href="/" class="go-to-h"
                          ><i class="fa fa-home" aria-hidden="true"></i> Go to
                          home</a
                        >
                      </div>
                    </div>
                    <!-- <div
                      v-if="message"
                      class="alert"
                      :class="successful ? 'alert-success' : 'alert-danger'"
                    >
                      {{ message }}
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-else-if="item" class="col-md-6 backwhite nomp">
                <div class="card card-container">
                  <form name="form" @submit.prevent="handlevendorregisterotp">
                    <div
                      v-if="!successful && item != 'Success'"
                      style="padding: 45px 100px"
                    >
                      <div class="form-group">
                        <label for="otp">OTP</label>
                        <input
                          v-model="user.otp"
                          v-validate="'required'"
                          type="text"
                          class="form-control"
                          name="otp"
                        />
                        <div
                          v-if="submitted && errors.has('otp')"
                          class="alert-danger"
                        >
                          {{ errors.first("otp") }}
                        </div>
                      </div>
                      <div class="form-group" style="margin-bottom: 0px">
                        <button
                          class="btn btn-primary btn-block loginBtn"
                          :disabled="loading"
                        >
                          <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                          ></span>
                          <span>Send</span>
                        </button>
                      </div>
                    </div>
                    <!-- <div
                      v-if="message"
                      class="alert"
                      :class="successful ? 'alert-success' : 'alert-danger'"
                      style="width: 66%; text-align: center; margin-left: 17%"
                    >
                      {{ message }}
                    </div> -->
                  </form>
                  <!-- <div
                    v-if="message && item != 'Success'"
                    class="alert"
                    :class="successful ? 'alert-success' : 'alert-danger'"
                  >
                    {{ message }}
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import User from "@/models/user";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import userService from "@/services/user.service";
// import config from "../../config";

export default {
  name: "Register",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      loading: false,
      message: "",
      imagesmessage: "",
      images: [],
      termsChecked: false,
      yourValue: "",
      resultsExample: null,
      iserror: false,
      phoneMessageError: "",
      item: "",
      showPassword: "",
      passwordType: "password",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  updated() {
    if (this.resultsExample != null) {
      if (this.resultsExample.isValid == true && this.phoneMessageError != "") {
        this.phoneMessageError = "";
        // console.log(this.resultsExample.isValid);
      }
    }
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/VendorProfile").catch(() => {
        "register";
      });
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    removeguestid(loadingTime) {
      setTimeout(() => this.$router.push({ path: "/" }), loadingTime);
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
      localStorage.setItem("userType", "vendor");
    },
    createImage(files) {
      var vm = this;

      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    handlevendorregisterotp() {
      this.message = "";
      this.submitted = true;
      this.loading = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          userService.vendorregisterotp(this.user, this.item).then(
            (data) => {
              if (data.data.Message == "Success") {
                this.item = data.data.Message;
                this.successful = true;
                this.makeToast(
                  "success",
                  "Registration completed successfully, need approval from Admin"
                );
                // this.message =
                //"Registration completed successfully, need approval from Admin";
                this.loading = false;

                this.removeguestid(4000);
              } else {
                // this.message = data.data.Message;
                this.makeToast("danger", data.data.Message);
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              let resMessage =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
              this.makeToast("danger", resMessage);
              this.successful = false;
            }
          );
        } else {
          this.loading = false;
        }
      });
    },

    handleRegister() {
      this.message = "";
      this.submitted = true;
      this.user.images = this.images[0];
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.imagesmessage = "";
          if (this.resultsExample.isValid == true) {
            this.phoneMessageError = "";
            this.user.phone =
              "+" +
              this.resultsExample.countryCallingCode +
              "-" +
              this.resultsExample.nationalNumber;
            this.loading = true;

            this.$store.dispatch("auth/vendorregister", this.user).then(
              (data) => {
                if (data.Message == "Success") {
                  this.item = data.Data[0];
                  this.loading = false;
                  this.successful = false;
                } else {
                  this.makeToast("danger", data.Message);
                  this.loading = false;
                }
              },
              (error) => {
                this.loading = false;
                let resMessage =
                  (error.response && error.response.data.Message) ||
                  error.message ||
                  error.toString();
                this.makeToast("danger", resMessage);
                this.successful = false;
              }
            );
          } else {
            this.loading = false;

            this.phoneMessageError = "Please check the phone number";
          }
        } else {
          this.loading = false;
        }
      });
    },
    makeToast(variant, body_content) {
      let tittleMessage = variant;
      if (variant == "danger") {
        tittleMessage = "Error";
      }  if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>

<style scoped>
.loginBtn {
  background-image: linear-gradient(to right, #000e88, #9000a7);
  border: none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  /* max-width: 370px !important; */
  padding: 40px 40px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  /* margin: 0 auto 25px; */
  margin: 15px 0px;

  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: none;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.tacbox {
  display: flex;
  /* padding: 1em; */
  /* margin: 2em; */
  /* border: 3px solid #ddd;
  background-color: #eee; */
  max-width: 800px;
}

.check {
  height: 3em;
  width: 1em;
  vertical-align: middle;
  margin-right: 9px;
}
.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
