<template>
  <div>


<a class="toggle-left-menu" @click="toggleList()"><i class="fas fa-bars"></i> Menu</a>

    <div>
      <ul
      v-if="showList"
        class="nav myaccount-tab-trigger"
        id="account-page-tab"
        role="tablist"
      >
        <li class="nav-item">
          <router-link
            class="nav-link vendorProfile"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Vendor"
            ><i class="fas fa-user-alt"> </i> Account</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/AddVehicle"
            ><i class="fas fa-gavel"> </i> Add vechicle</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/MyVehicleListings"
            ><i class="fas fa-car"> </i> Vehicles</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/LiveAuctions"
            ><i class="fa fa-hourglass-half"> </i> Active Auctions</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
             to="/AuctionHistory"
            ><i class="fa fa-history"></i> Auctions History</router-link

           
          >
        </li>
        <!-- <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/ProcessWinners"
            ><i class="fas fa-microchip"> </i> Process Winners</router-link
          >
        </li> -->
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/WonCustomer"
            ><i class="fas fa-trophy"> </i> Won Customers</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/PendingPayments"
            ><i class="fas fa-money-check-alt"> </i> Pending Payment
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/PaymentHistory"
            ><i class="fa fa-history" aria-hidden="true"></i> Payment
            History</router-link
          >
        </li>
        
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Lost"
            ><i class="fas fa-times-circle" aria-hidden="true"></i>
            Lost</router-link
          >
        </li>

        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-address-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-address"
            aria-selected="false"
            to="/VendorChangePassword"
            ><i class="fas fa-key"> </i> Change Password</router-link
          >
        </li>

        <li class="nav-item" v-if="currentUser">
          <a
            class="nav-link"
            id="account-details-tab"
            href
            @click.prevent="logOut"
            role="tab"
            ><i class="fas fa-lock"></i> Logout</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showList: true,
      currentuserinfo: "",
    };
  },
  mounted() {
    var className = "";

    if (window.location.href.indexOf("vendor") > -1) {
      className = document.getElementsByClassName("nav-link");
      for (var j = 0; j < className.length; j++) {
        if (className[j].className.includes("vendorProfile")) {
          className[j].classList.add("active");
        }
      }
    }

    className = document.getElementsByClassName("nav-link");
    for (var i = 0; i < className.length; i++) {
      if (className[i].className.includes("router-link-active")) {
        className[i].classList.add("active");
      }
    }

    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      if(this.isMobileScreen){
        this.showList = false;
      }
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
    },
    // logOut() {
    //   this.$store.dispatch("auth/logout");
    //   this.currentuserinfo = "";
    //   this.$router.push("/").catch(() => {
    //     "getuserbyid";
    //   });
    // },
    logOut() {
      this.$store.dispatch("auth/logout");

      location.reload();
      this.$router.push("/").catch(() => {
        "navbar";
      });
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    isMobileScreen: function () {
      return window.innerWidth <= 768; // You can adjust the width threshold as needed
    },
  },
};
</script>
<style scoped>
.pdtSliderhome {
  background-color: white;
  border-radius: 5px;
  margin: 10px 8px;
  padding: 5px;
}

.btn-upload {
  padding: 10px 20px;
  margin-left: 10px;
}
.upload-input-group {
  margin-bottom: 10px;
}

.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  height: 45px;
}
.imgUploadVehicle {
  margin-top: 20px;
  width: 100%;
}
.nav-item a:hover {
  background-color: #0094d9 !important;
  color: white !important;
}
.router-link-exact-active {
  border-bottom: 1px solid #123456;
}
</style>
