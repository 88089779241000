import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";

const API_URL = config.BASE_URL;
// const API_URL = 'http://localhost:8080/ibidzapi/';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", {
      headers: authHeader(),
    });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", {
      headers: authHeader(),
    });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", {
      headers: authHeader(),
    });
  }

  getCustomerDetailById(id) {
    return axios.get(API_URL + "?sp=getCustomerProfileById&custId=" + id, {
      headers: authHeader(),
    });
  }

  getBanners() {
    return axios.get(API_URL + "?sp=getBanners");
  }
  getSettings() {
    return axios.get(API_URL + "?sp=getSettings");
  }
  getVehicleTypes() {
    return axios.get(API_URL + "?sp=getVehicleTypes");
  }
  getVehicleModel(make) {
    return axios.get(API_URL + "?sp=getModel&brandName=" + make);
  }
  getVehicleVariant(model) {
    return axios.get(API_URL + "?sp=getVariant&modelName=" + model);
  }
  getVehicleBrand() {
    return axios.get(API_URL + "?sp=getBrand");
  }

  getVehicleFuelType() {
    return axios.get(API_URL + "?sp=getVehFuelType");
  }
  getInsuranceTypes() {
    return axios.get(API_URL + "?sp=getInsuranceTypes");
  }
  getsellerbyId(id) {
    return axios.get(API_URL + "?sp=getSellerProfileById&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  getWonCustomersAgainstSellerAuctions(id) {
    return axios.get(
      API_URL + "?sp=getWonCustomersAgainstSellerAuctions&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getPaymentHistoryAgainstSellerId(id) {
    return axios.get(
      API_URL + "?sp=getPaymentHistoryAgainstSellerId&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }

  getPendingPaymentHistoryAgainstSellerId(id) {
    return axios.get(
      API_URL + "?sp=getPendingPaymentHistoryAgainstSellerId&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getPaymentDueAgainstSellerAuctions(id) {
    return axios.get(
      API_URL + "?sp=getPaymentDueAgainstSellerAuctions&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getPackageClaimHistory(id) {
    return axios.get(API_URL + "?sp=getPackageHistory&custId=" + id);
  }
  getLostAuction(id) {
    return axios.get(API_URL + "?sp=getLostAuction&sellerId=" + id);
  }
  getProcessWinnersAgainstSellerAuctions(id) {
    return axios.get(
      API_URL + "?sp=getProcessWinnersAgainstSellerAuctions&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getProcessWinningsCustomer(id) {
    return axios.get(
      API_URL + "?sp=getProcessWinningsAgainstCustomer&custId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getCustomerWinnings(id) {
    return axios.get(API_URL + "?sp=getCustomerWinnings&custId=" + id, {
      headers: authHeader(),
    });
  }
  getclaimamount(id) {
    return axios.get(API_URL + "?sp=getclaimamount&custId=" + id, {
      headers: authHeader(),
    });
  }
  getPaymentDue(id) {
    return axios.get(API_URL + "?sp=getPaymentDue&custId=" + id, {
      headers: authHeader(),
    });
  }

  getVehiclesListBySellerId(id) {
    return axios.get(API_URL + "?sp=getVehiclesListBySellerId&sellerId=" + id, {
      headers: authHeader(),
    });
  }

  getAuctionListBySellerId(id) {
    return axios.get(API_URL + "?sp=getSellerAuctionsList&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  getLiveAuctionBySellerId(id) {
    return axios.get(API_URL + "?sp=getSellerLiveAuctionsList&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  deleteVehicle(id, vechId) {
    let payload = {
      sp: "delVehicleBySeller",
      vehId: vechId,
      sellerId: id,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  update(id, data) {
    let payload = {
      sp: "updCustomerProfile",
      fullName: data.custName,
      email: data.custEmail,
      phone: data.custPhone,
      custId: id,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  updCustomerSaleStatus(id, data) {
    let payload = {
      sp: "updCustomerSaleStatus",
      saleStatus: "Rejected",
      rejectReason: data.rejectReason,
      custId: id,
      aucId: data.aucId,
    };

    return axios.post(API_URL, payload);
  }
  getBidHistory(id, aucName) {
    let payload = {
      sp: "getBidHistory",
      custId: id,
      aucName: aucName,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  getRefundHistory(id, isAdmin) {
    let payload = {
      sp: "getRefundList",
      userId: id,
      isAdmin: isAdmin,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }

  reauctionRequst(aucId, sellerId) {
    let payload = {
      sp: "reauctionRequst",
      aucId: aucId,
      sellerId: sellerId,
    };
    return axios.post(API_URL, payload);
  }

  updVendorSaleStatus(id, data) {
    let payload = {
      sp: "updVendorSaleStatus",
      saleStatus: "SaleConfirm",
      sellerId: id,
      aucId: data.aucid,
      paymentMode: data.selected,
    };
    //console.log(payload);
    return axios.post(API_URL, payload);
  }
  updatesellerinfo(id, data) {
    let payload = {
      sp: "updSellerProfile",
      fullName: data.sellerName,
      email: data.sellerEmail,
      phone: data.sellerPhone,
      address: data.address,
      sellerId: id,
    };
    //  console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  updPackageOrder(data) {
    let payload = {
      sp: "updPackageOrder",
      razorpaySignature: data.razorpay_signature,
      razorpayIaymentId: data.razorpay_payment_id,
      razorpayOrderId: data.razorpay_order_id,
      orderStatus: 1,
    };

    return axios.post(API_URL, payload);
  }
  updOrders(data, claim_Status, packageListIds) {
    let payload = {
      sp: "updOrders",
      razorpaySignature: data.razorpay_signature,
      razorpayIaymentId: data.razorpay_payment_id,
      razorpayOrderId: data.razorpay_order_id,
      orderStatus: 1,
      claimStatus: claim_Status,
      packageListIds: packageListIds,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }
  updCommissionPaymentHistory(data) {
    let payload = {
      sp: "updCommissionPaymentHistory",
      razorpaySignature: data.razorpay_signature,
      razorpayIaymentId: data.razorpay_payment_id,
      razorpayOrderId: data.razorpay_order_id,
      orderStatus: 1,
    };

    return axios.post(API_URL, payload);
  }

  addVeh(id, veh, image, doc, defaultImage) {
    let image1 = "";
    let image2 = "";
    let image3 = "";
    let image4 = "";
    let image5 = "";
    let image6 = "";
    let image7 = "";
    let image8 = "";
    let image9 = "";
    let image10 = "";
    let image11 = "";
    let image12 = "";
    let image13 = "";
    let image14 = "";
    let image15 = "";
    let image16 = "";
    let image17 = "";
    let image18 = "";
    let image19 = "";
    let image20 = "";
    let image21 = "";
    let image22 = "";
    let image23 = "";
    let image24 = "";
    let image25 = "";
    let image26 = "";
    let image27 = "";
    let image28 = "";
    let image29 = "";
    let image30 = "";

    if (image.length > 0) {
      for (let index = 0; index < image.length; index++) {
        image1 = image[0];
        if (typeof image[1] != "undefined") {
          image2 = image[1];
        } else {
          image2 = "";
        }
        if (typeof image[2] != "undefined") {
          image3 = image[2];
        } else {
          image3 = "";
        }
        if (typeof image[3] != "undefined") {
          image4 = image[3];
        } else {
          image4 = "";
        }
        if (typeof image[4] != "undefined") {
          image5 = image[4];
        } else {
          image5 = "";
        }
        if (typeof image[5] != "undefined") {
          image6 = image[5];
        } else {
          image6 = "";
        }
        if (typeof image[6] != "undefined") {
          image7 = image[6];
        } else {
          image7 = "";
        }
        if (typeof image[7] != "undefined") {
          image8 = image[7];
        } else {
          image8 = "";
        }
        if (typeof image[8] != "undefined") {
          image9 = image[8];
        } else {
          image9 = "";
        }
        if (typeof image[9] != "undefined") {
          image10 = image[9];
        } else {
          image10 = "";
        }
        if (typeof image[10] != "undefined") {
          image11 = image[10];
        } else {
          image11 = "";
        }
        if (typeof image[11] != "undefined") {
          image12 = image[11];
        } else {
          image12 = "";
        }
        if (typeof image[12] != "undefined") {
          image13 = image[12];
        } else {
          image13 = "";
        }
        if (typeof image[13] != "undefined") {
          image14 = image[13];
        } else {
          image14 = "";
        }
        if (typeof image[14] != "undefined") {
          image15 = image[14];
        } else {
          image15 = "";
        }
        if (typeof image[15] != "undefined") {
          image16 = image[15];
        } else {
          image16 = "";
        }
        if (typeof image[16] != "undefined") {
          image17 = image[16];
        } else {
          image17 = "";
        }
        if (typeof image[17] != "undefined") {
          image18 = image[17];
        } else {
          image18 = "";
        }
        if (typeof image[18] != "undefined") {
          image19 = image[18];
        } else {
          image19 = "";
        }
        if (typeof image[19] != "undefined") {
          image20 = image[19];
        } else {
          image20 = "";
        }
        if (typeof image[20] != "undefined") {
          image21 = image[20];
        } else {
          image21 = "";
        }
        if (typeof image[21] != "undefined") {
          image22 = image[21];
        } else {
          image22 = "";
        }
        if (typeof image[22] != "undefined") {
          image23 = image[22];
        } else {
          image23 = "";
        }
        if (typeof image[23] != "undefined") {
          image24 = image[23];
        } else {
          image24 = "";
        }
        if (typeof image[24] != "undefined") {
          image25 = image[24];
        } else {
          image25 = "";
        }
        if (typeof image[25] != "undefined") {
          image26 = image[25];
        } else {
          image26 = "";
        }
        if (typeof image[26] != "undefined") {
          image27 = image[26];
        } else {
          image27 = "";
        }
        if (typeof image[27] != "undefined") {
          image28 = image[27];
        } else {
          image28 = "";
        }
        if (typeof image[28] != "undefined") {
          image29 = image[28];
        } else {
          image29 = "";
        }
        if (typeof image[29] != "undefined") {
          image30 = image[29];
        } else {
          image30 = "";
        }
      }
      //console.log("service vehicle info",veh);
      let payload = {
        sp: "insVehicle",
        sellerId: id.sellerId,
        vehRegNo: veh.vehRegNo,
        vehPrice: veh.vehPrice,
        vehMinPrice: 0,
        vehdescription: veh.vehdescription == null ? "" : veh.vehdescription,
        locId: veh.ct,
        kmClocked: veh.kmClocked == null ? "" : veh.kmClocked,
        manYear: veh.manYear == null ? "" : veh.manYear,
        underWarantee: veh.WarrantyState == null ? 0 : veh.WarrantyState,
        abs: veh.absState == null ? 0 : veh.absState,
        accidental: veh.accidentalState == null ? 0 : veh.accidentalState,
        adjustableSteering:
          veh.adjustableSteeringState == null ? 0 : veh.adjustableSteeringState,
        alloyWheels: veh.alloyWheelsState == null ? 0 : veh.alloyWheelsState,
        antiTheftDevice:
          veh.antiTheftDeviceState == null ? 0 : veh.antiTheftDeviceState,
        auxCompatibility:
          veh.auxCompatibilityState == null ? 0 : veh.auxCompatibilityState,
        Color: veh.colorState == null ? "" : veh.colorState,
        InsuranceTypeId: id.InsuranceTypeId == null ? "" : id.InsuranceTypeId,
        bluetooth: veh.bluetoothState == null ? 0 : veh.bluetoothState,
        navigationSystem:
          veh.comprehensiveNavigationSystemState == null
            ? 0
            : veh.comprehensiveNavigationSystemState,
        parkingSensors:
          veh.parkingSensorsState == null ? false : veh.parkingSensorsState,
        powerSteering:
          veh.powerSteeringState == null ? 0 : veh.powerSteeringState,
        FMRadio: veh.AMFMRadioState == null ? 0 : veh.AMFMRadioState,
        rearParkingCamera:
          veh.rearParkingCameraState == null ? 0 : veh.rearParkingCameraState,
        exchange: veh.exchangeState == null ? 0 : veh.exchangeState,
        finance: veh.financeState == null ? 0 : veh.financeState,
        serviceHistory:
          veh.serviceHistoryState == null ? 0 : veh.serviceHistoryState,
        usb: veh.usebSate == null ? 0 : veh.usebSate,
        HP: veh.hpState == "" ? "" : veh.hpState,
        AC: veh.ac == null ? 0 : veh.ac,
        vehicleCertified:
          veh.vehicleCertifiedState == null ? false : veh.vehicleCertifiedState,
        //"fuelType":id.fuelType == null ? "":  id.fuelType,
        variant: id.vehVariant,
        sunroof: veh.sunroofSate == null ? 0 : veh.sunroofSate,
        vehImage1: image1,
        vehImage2: image2,
        vehImage3: image3,
        vehImage4: image4,
        vehImage5: image5,
        vehImage6: image6,
        vehImage7: image7,
        vehImage8: image8,
        vehImage9: image9,
        vehImage10: image10,
        vehImage11: image11,
        vehImage12: image12,
        vehImage13: image13,
        vehImage14: image14,
        vehImage15: image15,
        vehImage16: image16,
        vehImage17: image17,
        vehImage18: image18,
        vehImage19: image19,
        vehImage20: image20,
        vehImage21: image21,
        vehImage22: image22,
        vehImage23: image23,
        vehImage24: image24,
        vehImage25: image25,
        vehImage26: image26,
        vehImage27: image27,
        vehImage28: image28,
        vehImage29: image29,
        vehImage30: image30,
        vehTypeId: id.vehTypeId,
        brandName: id.vehmake,
        modelName: id.vehmodel,
        vehicleHistoryUrl: doc == null ? "" : doc,
        fuelTypeId: id.fuelType,
        defaultImage: defaultImage == "" ? 0 : defaultImage,
        ownership: veh.ownership,
      };

      //console.log( "vehicle info" ,payload);
      return axios.post(API_URL, payload);
    }
  }
  updateVechDetails(paylod, image, vhImage, defaultImage) {
    let image1 = "";
    let image2 = "";
    let image3 = "";
    let image4 = "";
    let image5 = "";
    let image6 = "";
    let image7 = "";
    let image8 = "";
    let image9 = "";
    let image10 = "";
    let image11 = "";
    let image12 = "";
    let image13 = "";
    let image14 = "";
    let image15 = "";
    let image16 = "";
    let image17 = "";
    let image18 = "";
    let image19 = "";
    let image20 = "";
    let image21 = "";
    let image22 = "";
    let image23 = "";
    let image24 = "";
    let image25 = "";
    let image26 = "";
    let image27 = "";
    let image28 = "";
    let image29 = "";
    let image30 = "";
    if (image.length > 0) {
      for (let index = 0; index < image.length; index++) {
        if (image[0] != null) {
          image1 = image[0];
        } else {
          image1 = "";
        }
        if (image[1] != null) {
          image2 = image[1];
        } else {
          image2 = "";
        }
        if (image[2] != null) {
          image3 = image[2];
        } else {
          image3 = "";
        }
        if (image[3] != null) {
          image4 = image[3];
        } else {
          image4 = "";
        }
        if (image[4] != null) {
          image5 = image[4];
        } else {
          image5 = "";
        }
        if (image[5] != null) {
          image6 = image[5];
        } else {
          image6 = "";
        }
        if (image[6] != null) {
          image7 = image[6];
        } else {
          image7 = "";
        }
        if (image[7] != null) {
          image8 = image[7];
        } else {
          image8 = "";
        }
        if (image[8] != null) {
          image9 = image[8];
        } else {
          image9 = "";
        }
        if (image[9] != null) {
          image10 = image[9];
        } else {
          image10 = "";
        }
        if (image[10] != null) {
          image11 = image[10];
        } else {
          image11 = "";
        }
        if (image[11] != null) {
          image12 = image[11];
        } else {
          image12 = "";
        }
        if (image[12] != null) {
          image13 = image[12];
        } else {
          image13 = "";
        }
        if (image[13] != null) {
          image14 = image[13];
        } else {
          image14 = "";
        }
        if (image[14] != null) {
          image15 = image[14];
        } else {
          image15 = "";
        }
        if (image[15] != null) {
          image16 = image[15];
        } else {
          image16 = "";
        }
        if (image[16] != null) {
          image17 = image[16];
        } else {
          image17 = "";
        }
        if (image[17] != null) {
          image18 = image[17];
        } else {
          image18 = "";
        }
        if (image[18] != null) {
          image19 = image[18];
        } else {
          image19 = "";
        }
        if (image[19] != null) {
          image20 = image[19];
        } else {
          image20 = "";
        }
        if (image[20] != null) {
          image21 = image[20];
        } else {
          image21 = "";
        }
        if (image[21] != null) {
          image22 = image[21];
        } else {
          image22 = "";
        }
        if (image[22] != null) {
          image21 = image[22];
        } else {
          image21 = "";
        }
        if (image[23] != null) {
          image22 = image[23];
        } else {
          image22 = "";
        }
        if (image[24] != null) {
          image23 = image[24];
        } else {
          image23 = "";
        }
        if (image[25] != null) {
          image24 = image[25];
        } else {
          image24 = "";
        }
        if (image[26] != null) {
          image25 = image[26];
        } else {
          image25 = "";
        }
        if (image[27] != null) {
          image26 = image[27];
        } else {
          image26 = "";
        }
        if (image[28] != null) {
          image27 = image[28];
        } else {
          image27 = "";
        }
        if (image[29] != null) {
          image28 = image[29];
        } else {
          image28 = "";
        }
        if (image[30] != null) {
          image29 = image[30];
        } else {
          image29 = "";
        }
      }
    }

    let payload = {
      sp: "updVehicle",
      vehId: paylod.vehId,
      sellerId: paylod.sellerId,
      vehRegNo: paylod.vehRegNo,
      vehPrice: paylod.vehPrice,
      vehMinPrice: 0,
      vehdescription:
        paylod.vehdescription == null ? "" : paylod.vehdescription,
      locId: paylod.locationId,
      kmClocked: paylod.kmClocked,
      manYear: paylod.manYear,
      brandName: paylod.make,
      modelName: paylod.model,
      underWarantee:
        paylod.underWarantee == null ? false : paylod.underWarantee,
      abs: paylod.abs == null ? false : paylod.abs,
      accidental: paylod.accidental == null ? false : paylod.accidental,
      adjustableSteering:
        paylod.adjustableSteering == null ? false : paylod.adjustableSteering,
      alloyWheels: paylod.alloyWheels == null ? false : paylod.alloyWheels,
      antiTheftDevice:
        paylod.antiTheftDevice == null ? false : paylod.antiTheftDevice,
      auxCompatibility:
        paylod.auxCompatibility == null ? false : paylod.auxCompatibility,
      Color: paylod.Color == null ? "" : paylod.Color,
      InsuranceTypeId:
        paylod.InsuranceTypeId == null ? "" : paylod.InsuranceTypeId,
      bluetooth: paylod.bluetooth == null ? false : paylod.bluetooth,
      vehicleCertified:
        paylod.vehicleCertified == null ? false : paylod.vehicleCertified,
      navigationSystem:
        paylod.navigationSystem == null ? false : paylod.navigationSystem,
      parkingSensors:
        paylod.parkingSensors == null ? false : paylod.parkingSensors,
      powerSteering:
        paylod.powerSteering == null ? false : paylod.powerSteering,
      FMRadio: paylod.FMRadio == null ? false : paylod.FMRadio,
      rearParkingCamera:
        paylod.rearParkingCamera == null ? false : paylod.rearParkingCamera,
      exchange: paylod.exchange == null ? false : paylod.exchange,
      finance: paylod.finance == null ? false : paylod.finance,
      serviceHistory:
        paylod.serviceHistory == null ? false : paylod.serviceHistory,
      usb: paylod.usb == null ? false : paylod.usb,
      HP: paylod.HP == "" ? "" : paylod.HP,
      variant: paylod.variant,
      sunroof: paylod.sunroof == null ? false : paylod.sunroof,
      vehImage1: image1,
      vehImage2: image2,
      vehImage3: image3,
      vehImage4: image4,
      vehImage5: image5,
      vehImage6: image6,
      vehImage7: image7,
      vehImage8: image8,
      vehImage9: image9,
      vehImage10: image10,
      vehImage11: image11,
      vehImage12: image12,
      vehImage13: image13,
      vehImage14: image14,
      vehImage15: image15,
      vehImage16: image16,
      vehImage17: image17,
      vehImage18: image18,
      vehImage19: image19,
      vehImage20: image20,
      vehImage21: image21,
      vehImage22: image22,
      vehImage23: image23,
      vehImage24: image24,
      vehImage25: image25,
      vehImage26: image26,
      vehImage27: image27,
      vehImage28: image28,
      vehImage29: image29,
      vehImage30: image30,
      vehTypeId: paylod.vehTypeId,
      vehicleHistoryUrl: vhImage == null ? "" : vhImage,
      fuelTypeId: paylod.fuelType,
      AC: paylod.AC == null ? false : paylod.AC,
      isPremium: "",
      defaultImage: defaultImage == "" ? 0 : defaultImage,
      ownership: paylod.ownership,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }
  addToWhishlist(id, data) {
    let payload = {
      sp: "insCustomerWishlists",
      custId: id,
      vehId: data.vehId,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  addbid(item, custId, bidamount) {
    let bidamt = item.latestBidAmount + bidamount;
    let payload = {
      sp: "spBid",
      custId: custId,
      aucId: item.aucId,
      bidAmount: bidamt,
      userId: 0,
    };
    // console.log(payload);

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  generateWonOtp(item, custId) {
    let payload = {
      sp: "generateWonOtp",
      custId: custId,
      aucId: item.aucId,
    };
    //  console.log(payload);

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  vrifyCustomer(item, sellerId) {
    let payload = {
      sp: "vrifyCustomer",
      aucId: item.aucid,
      sellerId: sellerId,
      otp: item.otp,
    };
    // console.log(payload);

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  rmvfromWhishlist(data, cusid) {
    let payload = {
      sp: "delCustomerWishlists",
      custId: cusid,
      vehId: data.vehId,
    };
    // console.log();

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  changepassword(custId, data) {
    let payload = {
      sp: "changePassword",
      custId: custId,
      currentPassword: data.Currentpassword,
      newPassword: data.Newpassword,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  vendorchangepassword(sellerid, data) {
    let payload = {
      sp: "vendorchangePassword",
      sellerId: sellerid,
      currentPassword: data.Currentpassword,
      newPassword: data.Newpassword,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  forgotpassword(data) {
    let payload = {
      sp: "forgotPassword",
      username: data,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  vendorforgotpassword(data) {
    let payload = {
      sp: "vendorforgotpassword",
      username: data,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  vendorforgotpasswordotp(data, item) {
    let payload = {
      sp: "vendorresetPassword",
      sellerId: item.sellerId,
      otp: data.otp,
      password: data.Newpassword,
    };
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  forgotpasswordotp(data, item) {
    let payload = {
      sp: "resetPassword",
      custId: item.custId,
      otp: data.otp,
      password: data.Newpassword,
    };
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  getAuctionList(customerId, searchkey) {
    let payload = {
      sp: "searchVehicles",
      searchkey: searchkey,
      custId: customerId,
    };
    return axios.post(API_URL, payload);
  }

  getvehbyId(id) {
    return axios.get(API_URL + "?sp=getAuctionsList&vehId=" + id, {
      headers: authHeader(),
    });
  }
  getVehiclesDetailsvehId(sellerId, vehId) {
    return axios.get(
      API_URL +
        "?sp=getVehiclesDetailsvehId&sellerId=" +
        sellerId +
        "&vehId=" +
        vehId,
      {
        headers: authHeader(),
      }
    );
  }
  // getAuctionLogByvehId(vehId) {
  //   return axios.get(API_URL + '?sp=getAuctionLogByvehId&vehId=' + vehId, {
  //     headers: authHeader()
  //   });

  // }
  getbid(id) {
    return axios.get(API_URL + "?sp=getCustomerBids&custId=" + id, {
      headers: authHeader(),
    });
  }
  getCustomerauctionbyname(id) {
    return axios.get(API_URL + "?sp=getAuctionDetailsbyId&aucName=" + id);
  }
  getwonAuctionDetailsbyId(id) {
    return axios.get(API_URL + "?sp=getwonAuctionDetailsbyId&aucName=" + id);
  }
  getauctionbyname(vehId, sellerId, type) {
    return axios.get(
      API_URL +
        "?sp=getSellerAuctionsListbysellerIdVechId&sellerId=" +
        sellerId +
        "&vehId=" +
        vehId +
        "&type=" +
        type,
      {
        headers: authHeader(),
      }
    );
  }
  getauctionlogbyid(aucId) {
    // console.log(aucId);
    return axios.get(API_URL + "?sp=getAuctionLog&aucId=" + aucId);
  }
  todaysAuction(id) {
    return axios.get(API_URL + "?sp=getTodaysAuctions&custId=" + id);
  }
  hotauction(id) {
    return axios.get(API_URL + "?sp=getHotAuctions&custId=" + id);
  }
  getPremiumAuction(id) {
    return axios.get(API_URL + "?sp=getPremiumAuction&custId=" + id);
  }
  getStatebyLocId() {
    return axios.get(API_URL + "?sp=getStatebyLocId");
  }
  getDistrictbyLocId(id) {
    return axios.get(API_URL + "?sp=getDistrictbyLocId&state=" + id);
  }
  getLocationIdbyLocName(id) {
    return axios.get(API_URL + "?sp=getLocIdbylocName&locName=" + id);
  }
  getLocationByStateId(id) {
    return axios.get(API_URL + "?sp=getlocNamebyLocId&district=" + id);
  }
  getCustomerPackage(id) {
    return axios.get(API_URL + "?sp=getCustomerPackagesList&custId=" + id, {
      headers: authHeader(),
    });
  }
  getPackageHistory(id) {
    return axios.get(API_URL + "?sp=getCustomerPackageHistory&custId=" + id);
  }
  getDashboardProfileFe(id, type) {
    return axios.get(
      API_URL +
        "?sp=getDashboardProfileFe&Id=" +
        id +
        "&type=" +
        type +
        "&multiple=true"
    );
  }
  getNotificationList(id) {
    return axios.get(API_URL + "?sp=getNotificationList&Id=" + id);
  }
  getBidLogForAuctionDetailPage(id) {
    return axios.get(API_URL + "?sp=getBidLogForAuctionDetailPage&Id=" + id);
  }
  getCustomerPackagelist() {
    return axios.get(API_URL + "?sp=getAllPackages");
  }
  purchasePackage(paylod, id) {
    let payload = {
      sp: "razorPayInfo",
      custId: id,
      packId: paylod.packid,
    };
    return axios.post(API_URL, payload);
  }
  payTokenAmount(paylod, id, claim_Status, packageList) {
    let payload = {
      //sp: "aucRazorPay",
      sp: "spTokenPayPG",
      custId: id,
      aucId: paylod.aucId,
      claimStatus: claim_Status,
      packIds: packageList,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }
  ClaimTokenAmount(paylod, id) {
    let payload = {
      sp: "claimTokenAmount",
      custId: id,
      aucId: paylod.aucId,
    };
    return axios.post(API_URL, payload);
  }
  payCommissions(sumCommision, id, aucids) {
    let payload = {
      sp: "payCommissions",
      sellerId: id,
      commissionAmount: sumCommision,
      aucIds: aucids,
    };
    return axios.post(API_URL, payload);
  }
  sendFeedBack(feedback) {
    let payload = {
      sp: "insUserFeedBack",
      userName: feedback.name,
      userPhone: feedback.number,
      userEmail: feedback.email,
      userNote: feedback.textareavalue,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }
  vendorregisterotp(user, data) {
    return axios.post(API_URL, {
      sp: "confirmSellerRegisterOtp",
      sellerId: data.sellerId,
      OTP: user.otp,
    });
  }
  customerregisterotp(user, data) {
    return axios.post(API_URL, {
      sp: "confirmCustomerRegisterOtp",
      custId: data.custId,
      OTP: user.otp,
    });
  }
  ////////////////////////
  getVehicleMake() {
    return axios.get(API_URL + "?sp=getVehicleMake");
  }

  getVehicleModelList(make) {
    return axios.get(API_URL + "?sp=getVehicleModelList&make=" + make);
  }
}

export default new UserService();
