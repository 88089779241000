<template>
  <div id="app" v-cloak>
    <mobile-popup></mobile-popup>
    <navbar></navbar>
    <router-view />
    <foot></foot>
  </div>
</template>

<script>
import MobilePopup from '../src/components/MobilePopup.vue';

export default {
  components: {
    MobilePopup,
  },
};
</script>