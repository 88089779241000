export default class User {
    constructor(username, email, Currentpassword,Newpassword,reenterpassword,password,phone,otp,images,termsChecked) {
      this.username = username;
      this.email = email;
      this.phone = phone;
      this.Currentpassword = Currentpassword;
      this.Newpassword = Newpassword;
      this.reenterpassword = reenterpassword;
      this.password = password;
      this.otp = otp;
      this.images = images;
      this.termsChecked = termsChecked;
     
    }
  }
