<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      :variant="variantvalue"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p>{{ showmessage }}</p>
      <b-progress
        :variant="variantvalue"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    alertType: {
      type: String,
    },
    length: {
      type: Number,
    },
    message: {
      type: String,
    },
  },
  data() {
    return {
      dismissSecs: "",
      dismissCountDown: 0,
      showDismissibleAlert: false,
      variantvalue: "",
      messageContent: "",
    };
  },
  created() {
    this.showmessage = this.message;
    // this.messageContent = "Hello world" ;
    this.dismissSecs = this.length;
    // this.dismissSecs  = 10 ;
    // this.variantvalue = "info";
    this.variantvalue = this.alertType;
    this.showAlert();
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
  },
};
</script>
