<template>
  <div style="background-image: linear-gradient(to right, #0094da52 , #0b005c4f);display: none;">
    <div class="container" style="padding: 50px 0px;">
      <div>
      <h3 style="text-align: center;margin-bottom: 35px;">
        Car Types
      </h3>
    </div>
    <div style="padding-bottom: 50px">
      <TypeSlider :lists="vehicleTypes" :slidesno="vehicleTypes.length" class="car-types"></TypeSlider>
    </div>
    </div>
  </div>
</template>
<script>
// import carousel from "vue-owl-carousel";
import User from "../models/user";
import userService from "../services/user.service";
import Config from "../config";
export default {
  // components: { carousel },
  data() {
    return {
      search: "",
      vehicleTypes: [],
      path: Config.BASE_URL_IMG,

      user: new User("", ""),
      loading: false,
      message: "",
    };
  },
  watch: {
    changed_url(search) {
      this.search = search;
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {

    this.getVehicleTypes();
  },

  methods: {
    getVehicleTypes() {
      userService
        .getVehicleTypes()
        .then((response) => {
          this.vehicleTypes = response.data.Data;

         // console.log(this.vehicleTypes);
        })
        .catch((e) => {
          console.log(e);
          
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.car-types .owl-item img {
    display: block;
    width: 160px!important;
    margin: 0px auto;
}
</style>
