<template>
  <section class="container-fluid">
    <div class="row">
      <div class="col-md-6" v-for="(item, index) in lists" :key="index">
        <div>
          <router-link :to="item.Link">
            <img
              :src="path + item.imageUrl"
              style="width: 100%; height: auto; padding-bottom: 15px"
            />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Config from "../config";
export default {
  props: {
    lists: [],
    slidesno: Number,
  },
  data: function () {
    return {
      path: Config.BASE_URL_IMG,
    };
  },
};
</script>
