<template>
  <div>
    <div>
      <section
        class="loginformSection"
        style="background-image: url(assets/images/login-back.jpg)"
      >
        <div class="row nomp">
          <div class="col-md-12 nomp">
            <div class="row nomp">
              <div class="col-md-6 nomp">
                <div>
                  <div class="loginWelcome">
                    <!-- <h1>Welcome !</h1> -->
                    <h5>
                      Please Sign up to continue!<br />
                      <br />
                      <!-- Do you want to sell your car? <br />
                      If yes, sign up as a vendor for free.<br />
                      <br /> -->
                      <!-- <a
                        href="/vendorRegister"
                        style="
                          color: #525252;
                          background-color: #ffffff;
                          padding: 7px;
                          border-radius: 4px;
                          font-size: 15px;
                        "
                        >Go to vendor registration</a
                      > -->
                    </h5>
                  </div>
                </div>
              </div>

              <div class="col-md-6 backwhite nomp">
                <div v-if="!item">
                  <div class="card card-container">
                    <form name="form" @submit.prevent="handleRegister">
                      <div v-if="!successful">
                        <h5 class="b-b-head">Register as a Customer</h5>
                        <div class="form-group">
                          <label for="username">Full Name</label>
                          <input
                            v-model="user.username"
                            v-validate="'required|min:3|max:20'"
                            type="text"
                            class="form-control"
                            name="username"
                            placeholder="Full Name"
                          />
                          <div
                            v-if="submitted && errors.has('username')"
                            class="alert-danger"
                          >
                            {{ errors.first("username") }}
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="email">Email</label>
                          <input
                            v-model="user.email"
                            v-validate="'required|email|max:50'"
                            type="email"
                            class="form-control"
                            name="email"
                            placeholder="Email"
                          />
                          <div
                            v-if="submitted && errors.has('email')"
                            class="alert-danger"
                          >
                            {{ errors.first("email") }}
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="phone">Phone</label>
                          <VuePhoneNumberInput
                            v-model="yourValue"
                            :required="true"
                            :error="iserror"
                            color="red"
                            :noExample="true"
                            fetch-country
                            @update="resultsExample = $event"
                          />
                          <div
                            v-if="submitted && phoneMessageError"
                            class="alert-danger"
                          >
                            {{ phoneMessageError }}
                          </div>
                        </div>

                        <div class="form-group">
                          <label for="password">Password</label>
                          <input
                            v-model="user.password"
                            v-validate="'required|min:6|max:40'"
                            :type="passwordType"
                            class="form-control"
                            name="password"
                            placeholder="Password"
                          />
                          <div class="control passwordTypeCls">
                            <div class="button" @click="toggleShow">
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye-slash': showPassword,
                                    'fa-eye': !showPassword,
                                  }"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div
                            v-if="submitted && errors.has('password')"
                            class="alert-danger"
                          >
                            {{ errors.first("password") }}
                          </div>
                        </div>
                        <div class="tacbox">
                          <input
                            id="checkbox"
                            type="checkbox"
                            v-model="user.termsChecked"
                            name="check"
                            v-validate="'required'"
                            class="check"
                            key=""
                          />

                          <label for="checkbox">
                            By clicking 'Accept' you are agreeing to our
                            <a href="/CustomerTerms" target="_blank"
                              >Terms and Conditions</a
                            >.</label
                          >
                        </div>
                        <div
                          style="
                            margin: 5px 0px 10px 0px;
                            padding: 4px;
                            border-radius: 4px;
                          "
                          v-if="submitted && errors.has('check')"
                          class="alert-danger"
                        >
                          {{ errors.first("check") }}
                        </div>
                        <div class="form-group" style="margin-bottom: 0px">
                          <button
                            class="btn btn-primary btn-block loginBtn"
                            :disabled="loading"
                          >
                            <span
                              v-show="loading"
                              class="spinner-border spinner-border-sm"
                            ></span>                            
                            <span>Sign Up</span>
                          </button>
                        </div>
                      </div>
                    </form>
                    <div class="form-group">
                      <label for="password">
                        Already registered? <a @click="removeguestid(0)" class="ar-pl-log"
                          > <span class="a-r-p-l">Please Login</span></a
                        ></label
                      >
                      <div style="text-align: center">
                      <a href="/" class="go-to-h"><i class="fa fa-home" aria-hidden="true"></i> Go to home</a>
                    </div>
                    </div>
                    <!-- <div
                      v-if="message"
                      class="alert"
                      :class="successful ? 'alert-success' : 'alert-danger'"
                    >
                      {{ message }}
                    </div> -->
                  </div>
                </div>
                <div v-else-if="item">
                  <div class="card card-container">
                    <form
                      name="form"
                      @submit.prevent="handlecustomerregisterotp"
                    >
                      <div
                        v-if="!successful && item != 'Success'"
                        style="padding: 45px 0px"
                      >
                        <h5 class="b-b-head">Enter OTP</h5>
                        <div class="form-group">
                          <label for="otp">OTP</label>
                          <input
                            v-model="user.otp"
                            v-validate="'required'"
                            type="text"
                            class="form-control"
                            name="otp"
                            placeholder="Enter OTP"
                          />
                          <div
                            v-if="submitted && errors.has('otp')"
                            class="alert-danger"
                          >
                            {{ errors.first("otp") }}
                          </div>
                        </div>
                        <div class="form-group" style="margin-bottom: 0px">
                          <button
                            class="btn btn-primary btn-block loginBtn"
                            :disabled="loading"
                          >
                            <span
                              v-show="loading"
                              class="spinner-border spinner-border-sm"
                            ></span>
                            <span>Verify</span>
                          </button>
                        </div>
                      </div>
                      <!-- <div
                        v-if="message"
                        class="alert"
                        :class="successful ? 'alert-success' : 'alert-danger'"
                      >
                        {{ message }}
                      </div> -->
                    </form>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import User from "../models/user";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import userService from "../services/user.service";
// import config from "../config";
export default {
  name: "Register",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      user: new User("", "", ""),
      submitted: false,
      successful: false,
      loading: false,
      message: "",
      yourValue: "",
      resultsExample: null,
      iserror: false,
      phoneMessageError: "",
      item: "",
      passwordType: "password",
      showPassword:"",
      termsChecked:false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/MyAccount").catch(() => {
        "register";
      });
    }
  },
  updated() {
    if (this.resultsExample != null) {
      if (this.resultsExample.isValid == true && this.phoneMessageError != "") {
        this.phoneMessageError = "";
        // console.log(this.resultsExample.isValid);
      }
    }
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    removeguestid(loadingTime) {
      setTimeout(() => this.$router.push({ path: "/" }), loadingTime);
      localStorage.setItem(
        "guestid",
        "_" + Math.random().toString(36).substr(2, 9)
      );
      localStorage.setItem("userType", "customer");
    },
    handlecustomerregisterotp() {
      this.message = "";
      this.submitted = true;
      this.loading = true;
      this.$validator.validate().then((isValid) => {
        if (isValid) {
          userService.customerregisterotp(this.user, this.item).then(
            (data) => {
              if (data.data.Message == "Success") {
                this.item = data.data.Message;
                this.successful = true;
                // this.message = "Registration completed successfully";
                this.makeToast('success','Registration completed successfully');
                this.loading = false;
                this.removeguestid(5000);
              } else {
                this.message = data.data.Message;
                this.loading = false;
              }
            },
            (error) => {
              this.loading = false;
              let resMessage =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
                this.makeToast('danger',resMessage);
              this.successful = false;
            }
          );
        }
        else{
          this.loading = false;
        }
      });
    },
    handleRegister() {
      this.message = "";
      this.submitted = true;
      // console.log(this.resultsExample);

      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.imagesmessage = "";

          if (this.resultsExample.isValid == true) {
            this.phoneMessageError = "";

            this.user.phone =
              "+" +
              this.resultsExample.countryCallingCode +
              "-" +
              this.resultsExample.nationalNumber;
            this.loading = true;
            // console.log( this.user);
            this.$store.dispatch("auth/register", this.user).then(
              (data) => {
                if (data.Message == "Success") {
                  this.item = data.Data[0];
                  this.loading = false;
                  this.successful = false;
                  //console.log(data);
                } else {
                  let resMessage = data.Message;
                  this.makeToast('danger',resMessage);
                  this.loading = false;
                }
              },
              (error) => {
                this.loading = false;

                let resMessage  =
                  (error.response && error.response.data.Message) ||
                  error.message ||
                  error.toString();
                  this.makeToast('danger',resMessage);
                // console.log(this.message);
                this.successful = false;
              }
            );
          } else {
            this.loading = false;
            this.phoneMessageError = "Please check the phone number";
          }
        }
        else {
          this.loading = false;
        }
      });
    },
    makeToast(variant, body_content) {
      let tittleMessage =variant;
      if(variant == 'danger'){
        tittleMessage ='Error'
      }  if (variant == "success") {
        tittleMessage = "Success";
      }
      if (variant == "warning") {
        tittleMessage = "Warning";
      }
      this.$bvToast.toast(body_content, {
        title: `${tittleMessage || "default"}`,
        variant: variant,
        autoHideDelay: 5000,
        //solid: true,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>

<style scoped>
.loginBtn {
  background-image: linear-gradient(to right, #000e88, #9000a7);
  border: none;
}
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 370px !important;
  padding: 40px 40px;
}

.card {
  /* background-color: #f7f7f7; */
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: none;
  /* -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
input.form-control {
    font-size: 14px;
}
</style>
